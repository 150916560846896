import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import PageLoader from "../../components/PageLoader";
import { ICorporateRegion } from "../../model/ICorporateRegion";
import { FlightReport } from "../../model/IFlightReport";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { getCorporateRegion } from "../../services/domainServices/CorporateRegionDomainService";
import { FlightReportService } from "../../services/flightReport/FlightReportService";
import { getContractRegistration} from "../../services/HiringContractService";
import { hiringContractsState } from "../../state/DomainService.state";
import { crewTypeState, rateUnitState } from "../../state/FlyingHours.state";
import FlightReportDetailHeader from "./FlightReportDetailHeader";
import FlightReportSummary from "./FlightReportSummary";
import { FlightReportCRUD } from '../../operations/FlightReportCRUD';
import FlightReportStatus from "../../model/enum/FlightReportStatus";
import { FlyingDetailCRUD } from "../../operations/FlyingDetailCRUD";
import { bindFireNumberDropdown } from "../../services/domainServices/FireNumberService";
import { bindCrewTypeDropdown } from "../../services/domainServices/CrewTypeService";
import { bindCostingActivityDropdown } from "../../services/domainServices/CostingActivityService";
import { bindRateTypeDropdown } from "../../services/domainServices/RateTypeService";
import { bindRateUnitDropdown } from "../../services/domainServices/RateUnitService";
import { useAuth } from "react-oidc-context";
import { AuthenticationService } from "../../services/authentication/AuthenticationService";
import { bindInternalOrderDropdown } from "../../services/1GXCoding/InternalOrderService";
import { bindCostCenterDropdown } from "../../services/1GXCoding/CostCenterService";
import { bindFundDropdown } from "../../services/1GXCoding/FundService";
import { bindAccountDropdown } from "../../services/1GXCoding/AccountService";
import { IArrayOption } from "../../model/IArrayOption";

interface IFlightReportDetailProps {
  corporateRegions?:IPaginationResult<ICorporateRegion> | undefined
}

const FlightReportDetail : React.FunctionComponent<IFlightReportDetailProps> = ({corporateRegions, ...props}) => {
  const auth = useAuth();
  
  const { id } = useParams();
  const location = useLocation();

  const [flightReport, setFlightReport] = React.useState(new FlightReport());
  //Loader
  const [isLoading, setIsLoading] = React.useState(false);

  //Loader
  const [isEditing, setIsEditing] = React.useState(false);

  const corporateRegionPaginationResult = location.state;
  const setAircraftDetails = useSetRecoilState(hiringContractsState);
  //const setVendorDetails = useSetRecoilState(vendorDetailsState);

  const crewTypeStateResult = useRecoilValue(crewTypeState);
  const rateUnitStateResult = useRecoilValue(rateUnitState);
  
  React.useEffect(() => {
    if(id === "new")
    {
      setFlightReport(new FlightReport());
      getDomainServiceData();
      setIsEditing(true);
    }
    else if (id) {
      getFlightReport(id);
      setIsEditing(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getFlightReport = async (flightReportId: string) => {
    setIsLoading(true);

      await FlightReportService.getById(flightReportId, auth.user?.access_token as string)
        .then(async (response: any) => {
          //var vendorResult = []
          var vendorResult;
          //get aircraft registration
          var aircraftHiringContract = await FlightReportCRUD.getHiringContract(response.data.data.flightReportDate);
          
          if(aircraftHiringContract)
            setAircraftDetails(aircraftHiringContract);

            //Get contract registration
          if(response.data.data.contractRegistrationId)
            var contractRegistrationObj = getContractRegistration(aircraftHiringContract,response.data.data.contractRegistrationId)
        
          //Get flying registration
          if(response.data.data.flyingRegistrationId)
            var flyingRegistrationObj = getContractRegistration(aircraftHiringContract,response.data.data.flyingRegistrationId)

          vendorResult = {name: contractRegistrationObj?.name1, financeVendorId:contractRegistrationObj?.financeVendorId}

          //Get vendor based on contract
          // if (contractRegistrationObj && contractRegistrationObj.staId)
          // {
          //   var vendorByStakeholder: any = await VendorService.getByStackholderId(contractRegistrationObj.staId);
          //   if(vendorByStakeholder.data)
          //     vendorResult = vendorByStakeholder.data[0];
          // }
          // else{
          //   if(response.data.data.vendorId) //Get vendor
          //   {
          //     var vendorObj = await getVendor(response.data.data.vendorId);
          //     vendorResult = convertObjectFromOneSingleArrayValue(vendorObj as unknown as []);
          //   }
          // }

          //Get corporate region
          if(response.data.data.corporateRegionId)
            var corporateRegion = getCorporateRegion(corporateRegionPaginationResult,response.data.data.corporateRegionId);

          //set hiringStartDate, hiringEndDates, pointOfHire
          response.data.data.hiringStartDate = contractRegistrationObj?.effectiveDate;
          response.data.data.hiringEndDate = contractRegistrationObj?.terminationDate;
          response.data.data.pointOfHire = contractRegistrationObj?.pointOfHire;
          response.data.data.remark = contractRegistrationObj?.remark;
          
          //Set flight report with corporate region
          setFlightReport(new FlightReport({...response.data.data,corporateRegion:corporateRegion, vendor: vendorResult, 
                                          contractRegistration:contractRegistrationObj,flyingRegistration:flyingRegistrationObj}));

          if(response.data.data.status === "Signed off")
            setIsEditing(true);
          
          //Get domain service data for flying hours & expense
          getDomainServiceData(response.data.data.flightReportDate)
          //setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
  
  };

//  async function getVendor(vendorId: string) {
//     var response = await VendorService.getById(vendorId);
//     return response.data;
//   }

  function onSaveAndUpdate(value: FlightReport) {
    if(value != null && value !== undefined)
    {
      setFlightReport((p)=> {return new FlightReport(value)});
      setIsEditing(false);
    }
  }

  function onEdit(value:boolean)
  {
    if(flightReport.status !== FlightReportStatus.signedOff)
    {
      setIsEditing(value);
    }
    else{
      setIsEditing(true);
    }
  }

  //Drop down
  const [fireNumberOptions, setFireNumberOptions] = React.useState<IArrayOption[]>([]);
  const [costingActivityOptions, setCostingActivityOptions] = React.useState<IArrayOption[]>([]);
  const [rateTypeOptions, setRateTypeOptions] = React.useState<IArrayOption[]>([]);
  const [crewTypeOptions, setCrewTypeOptions] = React.useState<IArrayOption[]>([]);
  const [rateUnitOptions, setRateUnitOptions] = React.useState<IArrayOption[]>([]);

  //1GX coding financial
  const [internalOrderOptions, setInternalOrderOptions] = React.useState<IArrayOption[]>([]);
  const [costCenterOptions, setCostCenterOptions] = React.useState<IArrayOption[]>([]);
  const [fundOptions, setFundOptions] = React.useState<IArrayOption[]>([]);
  const [accountOptions, setAccountOptions] = React.useState<IArrayOption[]>([]);

  async function getDomainServiceData(flightReportDate:string=(new Date()).toDateString())
  {

      //Fire numbers
      await FlyingDetailCRUD.getFireNumbers(flightReportDate)
        .then(async (response: any) => {
          if(response)
          {
            const fireNumberResult = bindFireNumberDropdown(response.data);
            setFireNumberOptions(fireNumberResult);
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      
      //Crew types
      await FlyingDetailCRUD.getCrewTypes(flightReportDate)
        .then(async (response: any) => {
          if(response)
          {
            const crewTypeResult = bindCrewTypeDropdown(response.data);
            setCrewTypeOptions(crewTypeResult);
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      
      //Coting activity
      await FlyingDetailCRUD.getCostingActivities("",1000)
        .then(async (response: any) => {
          if(response)
          {
            const costingActivityResult = bindCostingActivityDropdown(response.data);
            setCostingActivityOptions(costingActivityResult);
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });

      //Rate type
      await FlyingDetailCRUD.getRateTypes(flightReportDate,'',1000)
        .then(async (response: any) => {
          if(response)
          {
            const rateTypeResult = bindRateTypeDropdown(response.data);
            setRateTypeOptions(rateTypeResult);
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      
      //Rate unit
      await FlyingDetailCRUD.getRateUnits(flightReportDate,'',1000)
        .then(async (response: any) => {
          if(response)
          {
            const rateUnitResult = bindRateUnitDropdown(response.data);
            setRateUnitOptions(rateUnitResult);
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });

      //Internal Order
      await FlyingDetailCRUD.getInternalOrder("", auth.user?.access_token as string)
      .then(async (response: any) => {
        if(response.data)
        {
          const internalOrderResult = bindInternalOrderDropdown(response.data);
          setInternalOrderOptions(internalOrderResult);
        }
      })
      .catch((e: Error) => {
        console.log(e);
        setIsLoading(false);
      });

      //Cost center
      await FlyingDetailCRUD.getCostCenter("", auth.user?.access_token as string)
      .then(async (response: any) => {
        if(response.data)
        {
          const costCenterResult = bindCostCenterDropdown(response.data);
          setCostCenterOptions(costCenterResult);
        }
      })
      .catch((e: Error) => {
        console.log(e);
        setIsLoading(false);
      });

      //Fund
      await FlyingDetailCRUD.getFund("", auth.user?.access_token as string)
      .then(async (response: any) => {
        if(response.data)
        {
          const fundResult = bindFundDropdown(response.data);
          setFundOptions(fundResult);
        }
      })
      .catch((e: Error) => {
        console.log(e);
        setIsLoading(false);
      });

      //Account
      await FlyingDetailCRUD.getAccount("", auth.user?.access_token as string)
      .then(async (response: any) => {
        if(response.data)
        {
          const accountResult = bindAccountDropdown(response.data);
          setAccountOptions(accountResult);
          setIsLoading(false);
        }
      })
      .catch((e: Error) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  return (
    <>
    
      <div >
        <PageLoader visible={isLoading} />

        {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_R',auth.user?.access_token as string) && <div>
          
        <div className="contentPadding detailPadding ">
          <div className="statusHeader">
            <FlightReportDetailHeader flightReport={flightReport} onUpdate={onSaveAndUpdate} isEditing={isEditing} onChange={onSaveAndUpdate} />
          </div>
        </div>

        <div>
          <div className="white-background new-layout-container">
            {!isLoading &&
              <FlightReportSummary flightReport={flightReport} corporateRegionResult={corporateRegionPaginationResult} crewTypeStateResult={crewTypeStateResult} rateUnitStateResult={rateUnitStateResult}
                        fireNumberOptions={fireNumberOptions} costingActivityOptions={costingActivityOptions} rateTypeOptions={rateTypeOptions} crewTypeOptions={crewTypeOptions} rateUnitOptions={rateUnitOptions}
                        internalOrderOptions = {internalOrderOptions} costCenterOptions={costCenterOptions} fundOptions = {fundOptions} accountOptions = {accountOptions}
                        onUpdate={onSaveAndUpdate} onEdit={onEdit} />
            }
          </div>
        </div>

        </div>}

      </div>
    </>
  );
};

export default FlightReportDetail;
