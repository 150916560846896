import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { FlightExpenses } from "../../model/IFlightExpense";
import { CostingActivityService } from "../domainServices/CostingActivityService";
import { FireNumberService } from "../domainServices/FireNumberService";
import { RateTypeService } from "../domainServices/RateTypeService";
import { RateUnitService } from "../domainServices/RateUnitService";

const getById = async (id: any, strToken:string = "") => {
  const data = await httpAviationApim.post<any>(
    `/expense/get/${id}`,
    "",
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  await Promise.all(
    data?.data?.data?.expenseDetails.map(async (record: any) => {
      if (record.fireNumberId) {
        var fireNumber = await getFireNumberByID(record.fireNumberId);
        record.fireNumber = fireNumber?.fireNumber;
      }

      if (record.costingActivityId) {
        var costingActivity = await getCostingActivityByID(
          record.costingActivityId
        );
        record.costingActivityCode = costingActivity?.code;
      }
      if (record.rateTypeId) {
        var rateType = await getRateTypeByID(record.rateTypeId);
        record.rateTypeEntity = rateType ?? undefined;
        record.rateType = rateType.type;
      }

      if (record.rateUnitId) {
        var rateUnit = await getRateUnitByID(record.rateUnitId);
        record.rateUnitEntity = rateUnit ?? undefined;
      }

      if (!record.ratePerUnit) {
        record.ratePerUnit = 0;
      }
    })
  );

  return data;
};

const createUpdate = async (flightExpenses: FlightExpenses, flightReportId: number, strToken:string = "") => {
  const params = JSON.stringify(flightExpenses);
 
  const data = await httpAviationApim.put<FlightExpenses>(
    `/expense/create-update/${flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  return data;
};

export const ExpenseService = {
  getById,
  createUpdate,
};

async function getCostingActivityByID(id: string) {
  try {
    var response: any = await CostingActivityService.getById(id);
    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
}

async function getRateTypeByID(id: string) {
  try {
    var response: any = await RateTypeService.getById(id);
    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
}

async function getRateUnitByID(rateUnitId: string) {
  try {
    var response: any = await RateUnitService.getById(rateUnitId);
    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
}

async function getFireNumberByID(fireNumberId: string) {
  try {
    var response: any = await FireNumberService.getById(fireNumberId);
    if (response.status) return response.data.data;
  } catch (error) {
    console.log("Error", error);
  }
}
