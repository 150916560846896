import moment from "moment";
import { IFilter } from "../model/search/IFilter";
import { IPagination } from "../model/search/IPagination";
import { ISearch } from "../model/search/ISearch";
import { RateTypeService } from "../services/domainServices/RateTypeService";
import { RateUnitService } from "../services/domainServices/RateUnitService";

//Rate type
async function getRateTypes(flightReportDate:string,searchValue:string = "",perPage:number=1500)
{
  let objIPagination: IPagination = {
    perPage: perPage,
    page: 1,
  };

  let objIFilter: IFilter = {
    negotiated: undefined,
    columnName: "",
    columnValue:""
  };

  let objISearch: ISearch = {
    serviceName: "aviation reporting expenses",
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await RateTypeService.getSearch(objISearch);
  return response;
}

//Rate unit
async function getRateUnits(flightReportDate:string,searchValue:string = "",perPage:number=1500)
{
  let objIPagination: IPagination = {
    perPage: perPage,
    page: 1,
  };

  let objIFilter: IFilter = {
    columnName: "activeDate",
    columnValue: moment(flightReportDate).format("yyyy-MM-DD"),
  };

  let objISearch: ISearch = {
    serviceName: "aviation reporting expenses",
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await RateUnitService.getSearch(objISearch);
  return response;
}

export const ExpenseDetailCRUD = {
    getRateTypes,
    getRateUnits,
  };