import moment from "moment";
import { IFilterOneGX } from "../model/1GXCoding/IFilterOneGX";
import { IFilter } from "../model/search/IFilter";
import { IPagination } from "../model/search/IPagination";
import { ISearch } from "../model/search/ISearch";
import { AccountService } from "../services/1GXCoding/AccountService";
import { CostCenterService } from "../services/1GXCoding/CostCenterService";
import { FundService } from "../services/1GXCoding/FundService";
import { InternalOrderService } from "../services/1GXCoding/InternalOrderService";
import { CostingActivityService } from "../services/domainServices/CostingActivityService";
import { CrewTypeService } from "../services/domainServices/CrewTypeService";
import { FireNumberService } from "../services/domainServices/FireNumberService";
import { RateTypeService } from "../services/domainServices/RateTypeService";
import { RateUnitService } from "../services/domainServices/RateUnitService";

//Fire number
async function getFireNumbers(flightReportDate:string = "",searchValue:string = "")
{
  let objIPagination: IPagination = {
    perPage: 1500,
    page: 1,
  };

  let objISearch: ISearch = {
    search: searchValue,
    year: new Date(flightReportDate).getUTCFullYear(),
    pagination: objIPagination,
  };

    var response = await FireNumberService.getSearch(objISearch);
    return response;
}

//Crew type
async function getCrewTypes(flightReportDate:string,searchValue:string = "")
{
  let objIPagination: IPagination = {
    perPage: 1500,
    page: 1,
  };

  let objIFilter: IFilter = {
    columnName: searchValue === "" ? "activeDate" : "",
    columnValue: searchValue === "" ? moment(flightReportDate).format("yyyy-MM-DD") : "",
  };

  let objISearch: ISearch = {
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await CrewTypeService.getSearch(objISearch);

  return response;
    
}

//Costing activity
async function getCostingActivities(searchValue:string = "FS",perPage:number=1500){
  let objIPagination: IPagination = {
    perPage: perPage,
    page: 1,
  };

  let objISearch: ISearch = {
    search: searchValue,
    pagination: objIPagination,
  };

  var response = await CostingActivityService.getSearch(objISearch);
  return response;
}

//Rate type
async function getRateTypes(flightReportDate:string, searchValue:string = "", perPage:number=1500)
{
  let objIPagination: IPagination = {
    perPage: perPage,
    page: 1,
  };

  let objIFilter: IFilter = {
    negotiated: true,
    columnName: "activeDate",
    columnValue: moment(flightReportDate).format("yyyy-MM-DD"),
  };

  let objISearch: ISearch = {
    serviceName: "aviation reporting flying hours",
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await RateTypeService.getSearch(objISearch);

  return response;
}

//Rate unit
async function getRateUnits(flightReportDate:string,searchValue:string = "",perPage:number=1500)
{
  let objIPagination: IPagination = {
    perPage: perPage,
    page: 1,
  };

  let objIFilter: IFilter = {
    columnName: "activeDate",
    columnValue: moment(flightReportDate).format("yyyy-MM-DD"),
  };

  let objISearch: ISearch = {
    serviceName: "aviation reporting flying hours",
    search: searchValue,
    sortBy: "",
    sortOrder: "",
    filterBy:objIFilter,
    pagination: objIPagination,
  };

  var response = await RateUnitService.getSearch(objISearch);
  return response;
}

//Internal Order
async function getInternalOrder(searchValue:string = "", strToken:string = "")
{
  let objIFilterOneGX: IFilterOneGX = {
    listType:"AviationCustomList",
    SkipRecords: 0,
    MaxRecords: 100,
    ActiveDate:"",
    Search:searchValue,
  };

  var response = await InternalOrderService.getSearch(objIFilterOneGX, strToken);
  return response;
}

//Cost center
async function getCostCenter(searchValue:string = "", strToken:string = "")
{
  let objIFilterOneGX: IFilterOneGX = {
    listType:"AviationCustomList",
    SkipRecords: 0,
    MaxRecords: 100,
    ActiveDate:"",
    Search:searchValue,
  };

  var response = await CostCenterService.getSearch(objIFilterOneGX, strToken);
  return response;
}

//Fund
async function getFund(searchValue:string = "", strToken:string = "")
{
  let objIFilterOneGX: IFilterOneGX = {
    listType:"AviationCustomList",
    SkipRecords: 0,
    MaxRecords: 100,
    ActiveDate:"",
    Search:searchValue,
  };

  var response = await FundService.getSearch(objIFilterOneGX, strToken);
  return response;
}

//Account
async function getAccount(searchValue:string = "", strToken:string = "")
{
  let objIFilterOneGX: IFilterOneGX = {
    listType:"AviationCustomList",
    SkipRecords: 0,
    MaxRecords: 100,
    ActiveDate:"",
    Search:searchValue,
  };

  var response = await AccountService.getSearch(objIFilterOneGX, strToken);
  return response;
}

export const FlyingDetailCRUD = {
    getFireNumbers,
    getCostingActivities,
    getRateTypes,
    getCrewTypes,
    getRateUnits,
    //1GX coding
    getInternalOrder,
    getCostCenter,
    getFund,
    getAccount,
  };