import {
  GoAButton,
  GoACheckbox,
  GoAInputText,
  GoATable,
} from "@abgov/react-components";
import moment from "moment";
import * as React from "react";
import { useAuth } from "react-oidc-context";
import Select from "react-select";
import { useRecoilValue } from "recoil";
import PageLoader from "../../components/PageLoader";
import ActionEvents from "../../model/enum/ActionEvents";
import ContractType from "../../model/enum/ContractType";
import { IContractRates } from "../../model/HiringContract/IContractRates";
import { IArrayOption } from "../../model/IArrayOption";
import { IFlightReport } from "../../model/IFlightReport";
import { FlyingHours } from "../../model/IFlyingHours";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { FlyingDetailCRUD } from "../../operations/FlyingDetailCRUD";
import { bindAccountDropdown } from "../../services/1GXCoding/AccountService";
import { bindCostCenterDropdown } from "../../services/1GXCoding/CostCenterService";
import { bindFundDropdown } from "../../services/1GXCoding/FundService";
import { bindInternalOrderDropdown } from "../../services/1GXCoding/InternalOrderService";
import { getContractRates } from "../../services/ContractRateService";
import { bindCostingActivityDropdown, CostingActivityService } from "../../services/domainServices/CostingActivityService";
import { bindCrewTypeDropdown, getCrewType } from "../../services/domainServices/CrewTypeService";
import { bindFireNumberDropdown, FireNumberService } from "../../services/domainServices/FireNumberService";
import { bindRateTypeDropdown, RateTypeService } from "../../services/domainServices/RateTypeService";
import { bindRateUnitDropdown, RateUnitService } from "../../services/domainServices/RateUnitService";
import { crewTypeState } from "../../state/FlyingHours.state";
import { validate } from "../../utils/commonMethods";

interface IEditFlyingDetailProps {
  ruleCode:string,
  flightReport:IFlightReport,
  arrFlyingHours: FlyingHours[];
  contractRatesPaginationResult:IPaginationResult<IContractRates> | undefined;
  fireNumberOptions:IArrayOption[];
  crewTypeOptions:IArrayOption[];
  costingActivityOptions:IArrayOption[];
  rateTypeOptions:IArrayOption[];
  rateUnitOptions:IArrayOption[];
  internalOrderOptions:IArrayOption[];
  costCenterOptions:IArrayOption[];
  fundOptions:IArrayOption[];
  accountOptions:IArrayOption[];
  onChange: (value: Array<FlyingHours>) => void;
  onChildDataValidityChange: any;
  onAddNewRow: (value: boolean) => void;
}

const EditFlyingDetail: React.FunctionComponent<IEditFlyingDetailProps> = ({flightReport,arrFlyingHours,contractRatesPaginationResult,
  fireNumberOptions,crewTypeOptions,costingActivityOptions,rateTypeOptions,rateUnitOptions,
  internalOrderOptions, costCenterOptions, fundOptions, accountOptions,
  onChange,onChildDataValidityChange, ruleCode,onAddNewRow,...props
}) => {
  const auth = useAuth();
  
  //Loader
  const [loading, setIsLoading] = React.useState(false);
  const [allSelected, setAllSelected] = React.useState(false);

  const [isCostingActivitySearch, setIsCostingActivitySearch] = React.useState(false);

  const [rowsFlyingHours, setRowsFlyingHours] = React.useState<FlyingHours[]>([]);
  // eslint-disable-next-line
  const [tempRowsFlyingHours, setTempRowsFlyingHours] = React.useState<FlyingHours[]>([]);

    //Recoil state
    const crewTypeStateResult = useRecoilValue(crewTypeState);
    
  React.useEffect(()=>{
    setIsLoading(true);
    
    if(arrFlyingHours)
    {
      setRowsFlyingHours(p => {return [...arrFlyingHours]});
      setTempRowsFlyingHours(p => {return [...arrFlyingHours]});
    }

    setIsLoading(false);
    
  },[arrFlyingHours])

  
  //Fire number
  async function getFireNumbers(idx:number = -1, searchValue:string = "")
  {
    try {
      var fireNumberResponse: any = await FlyingDetailCRUD.getFireNumbers(flightReport.flightReportDate, searchValue);
      if (fireNumberResponse.status)
      {
        const fireNumberResult = bindFireNumberDropdown(fireNumberResponse.data);

        if(idx !== -1 &&  rowsFlyingHours[idx] && fireNumberResult)
        {
          onDrodownSearch(idx,{fireNumberList:fireNumberResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  //Crew type
  async function getCrewTypes(idx:number = -1, searchValue:string = "")
  {
    try {
      var crewTypeResponse: any = await FlyingDetailCRUD.getCrewTypes(flightReport.flightReportDate,searchValue);
      if (crewTypeResponse.status)
      {
        const crewTypeResult = bindCrewTypeDropdown(crewTypeResponse.data);

        if(idx !== -1 && rowsFlyingHours[idx] && crewTypeResult)
        {
          onDrodownSearch(idx,{crewTypeList:crewTypeResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  //Costing activity
  async function getCostingActivities(idx:number = -1, searchValue:string = ""){
    try {
      var costingActivityResponse: any = await FlyingDetailCRUD.getCostingActivities(searchValue);
      if (costingActivityResponse.status)
      {
        const costingActivityResult = bindCostingActivityDropdown(costingActivityResponse.data);
        
        if(idx !== -1 && rowsFlyingHours[idx] && costingActivityResult)
        {
          onDrodownSearch(idx,{costingActivityList:costingActivityResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
}

  //Rate type
  async function getRateTypes(idx:number = -1, searchValue:string = "")
  {
    try {
      var rateTypeResponse: any = await FlyingDetailCRUD.getRateTypes(flightReport.flightReportDate, searchValue);

      if (rateTypeResponse.status)
      {
        const rateTypeResult = bindRateTypeDropdown(rateTypeResponse.data);

        if(idx !== -1 && rowsFlyingHours[idx] && rateTypeResult)
        {
          onDrodownSearch(idx,{rateTypeList:rateTypeResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  //Rate unit
  async function getRateUnits(idx:number = -1, searchValue:string = "")
  {
    try {
      var rateUnitResponse: any = await FlyingDetailCRUD.getRateUnits(flightReport.flightReportDate,searchValue);
      if (rateUnitResponse.status)
      {
        const rateUnitResult = bindRateUnitDropdown(rateUnitResponse.data);
        
        if(idx !== -1 && rowsFlyingHours[idx] && rateUnitResult)
        {
          onDrodownSearch(idx,{rateUnitList:rateUnitResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

//Internal order
async function getInternalOrder(idx:number = -1, searchValue:string = ""){
  try {
    var internalOrderResponse: any = await FlyingDetailCRUD.getInternalOrder(searchValue, auth.user?.access_token as string);
    if (internalOrderResponse.data)
    {
      const internalOrderResult = bindInternalOrderDropdown(internalOrderResponse.data);

      if(idx !== -1 && rowsFlyingHours[idx] && internalOrderResult)
      {
        onDrodownSearch(idx,{internalOrderList:internalOrderResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}

//Cost center
async function getCostCenter(idx:number = -1, searchValue:string = ""){
  try {
    var costCenterResponse: any = await FlyingDetailCRUD.getCostCenter(searchValue, auth.user?.access_token as string);
    if (costCenterResponse.data)
    {
      const costCenterResult = bindCostCenterDropdown(costCenterResponse.data);

      if(idx !== -1 && rowsFlyingHours[idx] && costCenterResult)
      {
        onDrodownSearch(idx,{costCenterList:costCenterResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}

//Fund
async function getFund(idx:number = -1, searchValue:string = ""){
  try {
    var fundResponse: any = await FlyingDetailCRUD.getFund(searchValue, auth.user?.access_token as string);
    if (fundResponse.data)
    {
      const fundResult = bindFundDropdown(fundResponse.data);

      if(idx !== -1 && rowsFlyingHours[idx] && fundResult)
      {
        onDrodownSearch(idx,{fundList:fundResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}

//Account
async function getAccount(idx:number = -1, searchValue:string = ""){
  try {
    var accountResponse: any = await FlyingDetailCRUD.getAccount(searchValue, auth.user?.access_token as string);
    if (accountResponse.data)
    {
      const accountResult = bindAccountDropdown(accountResponse.data);

      if(idx !== -1 && rowsFlyingHours[idx] && accountResult)
      {
        onDrodownSearch(idx,{accountList:accountResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}

  async function handleAddRow(){
    setIsLoading(true);

    const flyingHour = new FlyingHours();
    flyingHour.flightReportId = flightReport.flightReportId;
    flyingHour.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
    flyingHour.createUserId = auth?.user?.profile?.name ?? "wildfire";
    flyingHour.updateTimestamp = null;
    flyingHour.expenseType ="FlyingHours";

    //Dropdown
    flyingHour.fireNumberList = fireNumberOptions;
    flyingHour.crewTypeList = crewTypeOptions;
    flyingHour.costingActivityList = costingActivityOptions;
    flyingHour.rateTypeList = rateTypeOptions;
    flyingHour.rateUnitList = rateUnitOptions;

    //1GX coding - financial
    flyingHour.internalOrderList = internalOrderOptions;
    flyingHour.costCenterList = costCenterOptions;
    flyingHour.fundList = fundOptions;
    flyingHour.accountList = accountOptions;
    
    var rateType,contractRates;
    if(contractRatesPaginationResult)
    {
      if(flightReport?.contractRegistration?.contractType === ContractType.long)
      {
        //Primary
        if(contractRatesPaginationResult.data.find(value => value.crtyId === 279))
        {
          rateType = rateTypeOptions.find((t)=> t.label==="Primary")
        }
        //Secondary
        else{ 
          rateType = rateTypeOptions.find((t)=> t.label==="Secondary")
        }
      }

      else if(flightReport?.contractRegistration?.contractType === ContractType.casual)
      {
        rateType = rateTypeOptions.find((t)=> t.label==="Dry")
      }

      if(rateType)
      {
        var rateTypeAPI : any = await RateTypeService.getByOracleId(rateType.oracleId ?? 0);
        contractRates  = getContractRates(contractRatesPaginationResult, rateType.oracleId ?? 0);

        flyingHour.rateTypeId = rateTypeAPI ? rateTypeAPI.data.rateTypeId : undefined;
        flyingHour.rateTypeEntity = rateTypeAPI.data;
        flyingHour.ratePerUnit = contractRates ? contractRates?.ratePerUnit : 0;

        if(contractRates)
        {
          var rateUnit : any = await RateUnitService.getByOracleId(contractRates?.cruId ?? 0);

          flyingHour.rateUnitId = rateUnit ? rateUnit?.data?.rateUnitId : undefined;
          flyingHour.rateUnitEntity = rateUnit?.data;
        }
      }
    }
     
    setRowsFlyingHours(p => {return [...rowsFlyingHours,flyingHour]});

    onAddNewRow(false);
    setIsLoading(false);
  };

  function onDrodownSearch(idx:number,value: Partial<FlyingHours>)
  {
    let newValue = new FlyingHours(rowsFlyingHours[idx]);

    newValue =  new FlyingHours({...newValue, ...value});
    let rows = [...rowsFlyingHours];
    rows[idx] = newValue;

    setRowsFlyingHours(p=> {return rows});
  }

  //on property values change
  function onPropertyChange(idx:number,value: Partial<FlyingHours>) {
    let newValue = new FlyingHours(rowsFlyingHours[idx]);

    if(newValue.flightReportCostDetailsId !== "00000000-0000-0000-0000-000000000000")
    {
      newValue.updateTimestamp = moment(new Date()).format("yyyy-MM-DD");
      newValue.updateUserId = auth?.user?.profile?.name ?? "wildfire"; 
    }

    newValue =  new FlyingHours({...newValue, ...value});
    let rows = [...rowsFlyingHours];
    rows[idx] = newValue;
    setRowsFlyingHours(p=> {return rows});
    
    //let updatedFlyingRows = rows.find(x=>!x.deleted);
    //send on change to parent component "FlightReportSummary"
    onChange(rows);
  }

  return (
    <>
    <PageLoader visible={loading} />
    
      <div className="white-background">

        <GoATable width="100%">
          <thead>
            <tr>
              {rowsFlyingHours.length > 0 ?
              <th className="width2">
                  <GoACheckbox name="chkAll" text="" value="0" checked={allSelected} 
                    onChange={(name, checked, value) => {
                      setAllSelected(checked);
                      rowsFlyingHours.forEach((record) => {record.isChecked = checked});
                      //send on change to parent component "FlightReportSummary"
                      onChange(rowsFlyingHours);
                    }}
                  ></GoACheckbox>
              </th>: <></>}
              <th className="width10">Fire number</th>
              <th className="width13">Crew type on board</th>
              <th className="width13">Activity</th>
              <th className="width8">Rate type</th>
              <th className="width7">No.of units</th>
              <th className="width8">Unit</th>
              <th className="width5">Rate per unit</th>
              <th>Cost</th>
              <th className="width8">Internal order</th>
              <th className="width8">Cost centre</th>
              <th className="width8">Fund</th>
              <th className="width8">Account</th>
            </tr>
          </thead>

          <tbody style={{ position: "sticky", top: 0 }}>
              {rowsFlyingHours ? 
                rowsFlyingHours.filter(x=> !x.deleted).map((_record: any, idx: any) => (
                  <tr key={idx}>
                    <td>
                      <GoACheckbox name={"chkRow"+idx} text="" value={_record.flightReportCostDetailsId} checked={_record.isChecked}
                        onChange={(name, checked, value) => {
                          onPropertyChange(idx,{isChecked:checked})
                          if(!checked)
                            setAllSelected(false);
                        }}>
                      </GoACheckbox>
                    </td> 
                    <td>
                      <Select
                        name={"selFireNmbers"+idx}
                        options={_record?.fireNumberList}
                        placeholder="--Select--"
                        className="width100"
                        //value={fireNumberOptions.find(t => t.value === _record?.fireNumberId) }
                        value={_record?.fireNumberId !== undefined ? _record?.fireNumberList.find((t: { value: any; }) => t.value === _record?.fireNumberId) :
                                                     _record?.fireNumberList.find((t: { value: any; }) => t.value === _record?.fireNumberId) }
                        onChange={async (value:any) =>{
                          if(value.value)
                          {
                            var fireNumber : any = await FireNumberService.getById(value.value);
                            setIsCostingActivitySearch(false);
                            onPropertyChange(idx,{fireNumberId:value.value, fireNumber:value.fireNumber, fireYear:value.fireYear, fireNumberEntity: fireNumber?.data?.data,
                                                  costingActivityId:undefined, costingActivityCode:"", costingActivityEntity:undefined});
                          }
                          else{
                            onPropertyChange(idx,{fireNumberId:undefined, fireNumber:"", fireYear:0, fireNumberEntity:undefined, 
                                                  costingActivityId:undefined, costingActivityCode:"", costingActivityEntity:undefined});
                          }
                        }}
                        onInputChange={(value:any, actionMeta:any)=>{
                          if(actionMeta.action === ActionEvents.inputChange)
                            getFireNumbers(idx,value);
                        }}
                        isSearchable={true}
                      />
                    </td>
                    <td>
                      <Select
                        name={"selCrewTypeOnBoard"+idx}
                        options={_record?.crewTypeList}
                        placeholder="--Select--"
                        className="width100"
                        value={_record?.crewTypeList.find((t: { value: any; }) => t.value === _record?.crewTypeId) }
                        onChange={async (value:any) =>{
                          if(value.value)
                          {
                            var crewType : any = await getCrewType(crewTypeStateResult,value.value);
                            onPropertyChange(idx,{crewTypeId:value.value,crewTypeEntity:crewType})
                          }
                          else{
                            onPropertyChange(idx,{crewTypeId:null,crewTypeEntity:undefined})
                          }
                        }}
                        onInputChange={(value:any, actionMeta:any)=>{
                          if(actionMeta.action === ActionEvents.inputChange)
                            getCrewTypes(idx,value);
                        }}
                        isSearchable={true}
                      />
                    </td>
                      <td>
                      <Select
                        name= {"selCostingActivity" + idx}
                        options={ _record.fireNumberId && isCostingActivitySearch ? _record?.costingActivityList :
                                  _record.fireNumberId && !isCostingActivitySearch ? _record?.costingActivityList.filter((x: any) => x.label.startsWith("FS")) :
                                  _record?.costingActivityList
                                }
                        placeholder="--Select--"
                        className="width100"
                        value={_record?.costingActivityId !== "00000000-0000-0000-0000-000000000000" ? _record?.costingActivityList.find((x: any ) => x.value === _record?.costingActivityId) : ''}
                        onChange={async (value:any) =>{
                          if(value.value)
                          {
                            var costingActivity : any = await CostingActivityService.getById(value.value);
                            onPropertyChange(idx,{costingActivityId:value.value,costingActivityCode:value.code,costingActivityEntity:costingActivity.data});
                            onChildDataValidityChange(validate(ruleCode, "selCostingActivity" + idx, "onChange", value.label))
                          }
                          setIsCostingActivitySearch(false);
                        }}
                        onInputChange={(value:any, actionMeta:any)=>{
                          if(actionMeta.action === ActionEvents.inputChange)
                          {
                            setIsCostingActivitySearch(true);
                            getCostingActivities(idx,value);
                          }
                        }}
                        isSearchable={true}
                      />
                    </td>
                    <td>
                      <Select
                        name={"selRateType"+idx}
                        options={_record?.rateTypeList}
                        placeholder="--Select--"
                        className="width100"
                        value={_record?.rateTypeList?.find((t: { value: any; }) => t.value === _record?.rateTypeId) }
                        onChange={async (value:any) =>{
                          if(value.value)
                          {
                            var rateType : any = await RateTypeService.getById(value.value);
                          
                            if(rateType && contractRatesPaginationResult)
                              var ratePerUnit = getContractRates(contractRatesPaginationResult,rateType?.data?.oracleId)

                            onPropertyChange(idx,{rateTypeId:value.value,rateTypeEntity:rateType.data,ratePerUnit:ratePerUnit?.ratePerUnit})
                          }
                        }}
                        onInputChange={(value:any, actionMeta:any)=>{
                          if(actionMeta.action === ActionEvents.inputChange)
                            getRateTypes(idx,value);
                        }}
                        isSearchable={true}
                      />
                    </td>
                    <td>
                      <GoAInputText
                          name={"txtNoOfUnits"+idx}
                          value={_record?.noOfUnits}
                          width="100%"
                          maxLength={10}
                          onChange={(name, value) => {
                            if(value)
                              onPropertyChange(idx,{noOfUnits:value})}
                          }
                        />
                    </td>
                    <td>
                      <Select
                        name={"selRateUnit"+idx}
                        options={_record?.rateUnitList}
                        placeholder="--Select--"
                        className="width100"
                        isDisabled={contractRatesPaginationResult && contractRatesPaginationResult.data.find(value => value.crtyId === _record?.rateTypeEntity?.oracleId) ? true : false}
                        value={_record?.rateUnitList.find((t: { value: any; }) => t.value === _record?.rateUnitId) }
                        onChange={async (value:any) =>{
                          var rateUnit : any = await RateUnitService.getById(value.value);
                          onPropertyChange(idx,{rateUnitId:value.value,rateUnitEntity:rateUnit.data})
                        }}
                        onInputChange={(value:any, actionMeta:any)=>{
                          if(actionMeta.action === ActionEvents.inputChange)
                            getRateUnits(idx,value);
                        }}
                        isSearchable={true}
                      />
                    </td>
                    <td>{_record?.ratePerUnit > 0 && contractRatesPaginationResult && contractRatesPaginationResult.data.find(value => value.crtyId === _record?.rateTypeEntity?.oracleId) ?  
                        "$"+_record?.ratePerUnit
                        : <GoAInputText
                        name={"txtRatePerUnit"+idx}
                        value={_record?.ratePerUnit ? _record?.ratePerUnit : ""}
                        width="100%"
                        maxLength={10}
                        onChange={(name, value) => {onPropertyChange(idx,{ratePerUnit: value})}}
                        /> }
                    </td>
                    <td>{"$"+_record?.cost}</td>
                    <td>
                      <Select
                        name={"selInternalOrders"+idx}
                        options={_record?.internalOrderList }
                        placeholder="--Select--"
                        className="width100"
                        value={_record?.internalOrderList?.find((t: { value: any; }) => t.value === _record?.internalOrder)}
                        onChange={async (value:any) =>{
                          if(value.value)
                          {
                            onPropertyChange(idx,{internalOrder:value.value});
                          }
                        }}
                        onInputChange={(value:any, actionMeta:any)=>{
                          if(actionMeta.action === ActionEvents.inputChange)
                            getInternalOrder(idx,value);
                        }}
                        isSearchable={true}
                      />
                    </td>
                    <td>
                      <Select
                          name={"selCostCenter"+idx}
                          options={ _record?.costCenterList }
                          placeholder="--Select--"
                          className="width100"
                          value={_record?.costCenterList?.find((t: any) => t.value === _record?.costCenter)}
                          onChange={async (value:any) =>{
                            if(value.value)
                            {
                              onPropertyChange(idx,{costCenter:value.value});
                            }
                          }}
                          onInputChange={(value:any, actionMeta:any)=>{
                            if(actionMeta.action === ActionEvents.inputChange)
                              getCostCenter(idx,value);
                          }}
                          isSearchable={true}
                        />
                    </td>
                    <td>
                      <Select
                        name={"selFund"+idx}
                        options={ _record?.fundList }
                        placeholder="--Select--"
                        className="width100"
                        value={_record?.fundList?.find((t: any) => t.value === _record?.fund)}
                        onChange={async (value:any) =>{
                          if(value.value)
                          {
                            onPropertyChange(idx,{fund:value.value});
                          }
                        }}
                        onInputChange={(value:any, actionMeta:any)=>{
                          if(actionMeta.action === ActionEvents.inputChange)
                            getFund(idx,value);
                        }}
                        isSearchable={true}
                      />
                    </td>
                    <td>
                      <Select
                          name={"selAccount"+idx}
                          options={ _record?.accountList }
                          placeholder="--Select--"
                          className="width100"
                          value={_record?.accountList?.find((t: any) => t.value === _record?.account)}
                          onChange={async (value:any) =>{
                            if(value.value)
                            {
                              onPropertyChange(idx,{account:value.value});
                            }
                          }}
                          onInputChange={(value:any, actionMeta:any)=>{
                            if(actionMeta.action === ActionEvents.inputChange)
                              getAccount(idx,value);
                          }}
                          isSearchable={true}
                        />
                    </td> 
                  </tr>
              )) : 
              <></>
              }
            </tbody>
        </GoATable>
      </div>

      <div className="flying-hours">
        <GoAButton type="secondary" trailingIcon="add" disabled={flightReport.noFlyDay}
          onClick={() => handleAddRow()}>
          Add new
        </GoAButton>
      </div>

    </>
  );
};

export default EditFlyingDetail;