import {
  GoABadge,
  GoAButton,
  GoAButtonGroup,
  GoADetails,
  GoAIcon,
  GoAModal,
  GoATab,
  GoATabs,
} from "@abgov/react-components";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import PageLoader from "../../components/PageLoader";
import { FlightReport } from "../../model/IFlightReport";
import { getCorporateRegion } from "../../services/domainServices/CorporateRegionDomainService";
import { FlightReportService } from "../../services/flightReport/FlightReportService";
import {
  hiringContractsState,
} from "../../state/DomainService.state";
import EditFlightReportSummary from "./EditFlightReportSummary";
import EditFlyingDetail from "../flyingDetails/EditFlyingDetail";
import ViewFlightReportSummary from "./ViewFlightReportSummary";
import ViewFlyingDetail from "../flyingDetails/ViewFlyingDetail";
import { FlyingHours, IFlyingHours } from "../../model/IFlyingHours";
import { FlyingHoursService } from "../../services/flyingHours/FlyingHoursService";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { IPagination } from "../../model/search/IPagination";
import { IFilter } from "../../model/search/IFilter";
import { ISearch } from "../../model/search/ISearch";

import { CrewTypeService } from "../../services/domainServices/CrewTypeService";
import { CostingActivityService } from "../../services/domainServices/CostingActivityService";
import { RateTypeService } from "../../services/domainServices/RateTypeService";
import { validationEnginService } from "../../services/ValidationEnginService";
import { validate } from "../../utils/commonMethods";
import { RateUnitService, } from "../../services/domainServices/RateUnitService";
import { getContractRegistration, HiringContractService } from "../../services/HiringContractService";
import { FireNumberService } from "../../services/domainServices/FireNumberService";
import { IHiringContract } from "../../model/HiringContract/IHiringContract";
import { IContractRates } from "../../model/HiringContract/IContractRates";
import { ICrewType } from "../../model/domainServices/ICrewType";
import { IRateUnit } from "../../model/domainServices/IRateUnit";
import { ExpenseService } from "../../services/expense/ExpensesService";
import { useState } from "react";
import { FlightExpenses } from "../../model/IFlightExpense";
import CalloutVariant from "../../model/enum/CalloutVariant";
import axios from "axios";
import { IValidationError } from "../../model/errors/IValidationError";
import EditExpenseDetails from "../expense/EditExpenseDetails";
import ViewExpenseDetails from "../expense/ViewExpenseDetails";
import { ICorporateRegion } from "../../model/ICorporateRegion";
import FlightReportNotes from "../notes/FlightReportNotes";
import ViewFlightReportHiringDetails from "./ViewFlightReportHiringDetails";
import moment from "moment";
import { notesService } from "../../services/notes/NotesService";
import { FlightReportNote } from "../../model/IFlightReportNote";
import { useAuth } from "react-oidc-context";
import { AuthenticationService } from "../../services/authentication/AuthenticationService";
import { IArrayOption } from "../../model/IArrayOption";

interface IFlightReportSummaryProps {
  flightReport: FlightReport;
  corporateRegionResult: IPaginationResult<ICorporateRegion>;
  crewTypeStateResult: IPaginationResult<ICrewType>;
  rateUnitStateResult: IPaginationResult<IRateUnit>;
  fireNumberOptions: IArrayOption[];
  costingActivityOptions: IArrayOption[];
  rateTypeOptions: IArrayOption[];
  crewTypeOptions: IArrayOption[];
  rateUnitOptions: IArrayOption[];
  internalOrderOptions:IArrayOption[];
  costCenterOptions:IArrayOption[];
  fundOptions:IArrayOption[];
  accountOptions:IArrayOption[];
  onEdit: (value: boolean) => void;
  onUpdate: (value: FlightReport) => void;
}

const FlightReportSummary: React.FunctionComponent<
  IFlightReportSummaryProps
> = ({
  flightReport,
  corporateRegionResult,
  crewTypeStateResult,
  rateUnitStateResult,
  fireNumberOptions,
  costingActivityOptions,
  rateTypeOptions,
  crewTypeOptions,
  rateUnitOptions,
  internalOrderOptions, costCenterOptions, fundOptions, accountOptions,
  onEdit,
  onUpdate,
}) => {
    const auth = useAuth();

    const [isChildDataValid, setIsChildDataValid] = useState(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorItems, setErrorItems] = useState<JSX.Element[]>([]);

    const handleChildDataValidity = (errorMessages: []) => {
      if (errorMessages) {
        setIsChildDataValid(errorMessages.length === 0);
        setErrorItems(
          errorMessages.map((item, index) => (
            <li key={index} style={{ color: "red" }}>
              {item}
            </li>
          ))
        );
      }
      return errorMessages;
    };

    const navigate = useNavigate();
    const [isView, setIsView] = React.useState(true);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isUpdate, setIsUpdate] = React.useState(false);

    const [tempFlightReport, setTempFlightReport] = React.useState(new FlightReport(flightReport));
    const [updateFlightReport, setUpdateFlightReport] = React.useState(new FlightReport(flightReport));

    const [showModal, setShowModal] = React.useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = React.useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [modalHeading] = React.useState("");
    const [calloutVariant, setCalloutVariant] = useState(CalloutVariant.success);

    //No Fly day modal
    const [showNoFlyDayModal, setShowNoFlyDayModal] = React.useState(false);

    //Loader
    const [loading, setIsLoading] = React.useState(false);

    const corporateRegionPaginationResult = corporateRegionResult;

    //Flying hours
    const [isFlyingHoursEdit, setIsFlyingHoursEdit] = React.useState(false);
    const [isFlyingHoursUpdate, setIsFlyingHoursUpdate] = React.useState(false);
    const [isFlyingHoursDelete, setIsFlyingHoursDelete] = React.useState(false);
    const [isFlyingHoursRowSelected, setIsFlyingHoursRowSelected] = React.useState(false);
    const [totalFlyingHoursRowSelected, setTotalFlyingHoursRowSelected] = React.useState("");

    //Flying hours delete
    const [showFlyingHoursDeleteModal, setShowFlyingHoursDeleteModal] = React.useState(false);
    const [, setIsFlyingHoursDeleteMode] = React.useState(false);
    

    //Flight Expenses
    const [isFlightExpensesUpdate, setIsFlightExpensesUpdate] = React.useState(false);
    const [isFlightExpensesEdit, setIsFlightExpensesEdit] = React.useState(false);
    const [expensesData, setExpenseData] = React.useState<FlightExpenses>();
    const [updateFlightExpenses, setUpdateFlightExpenses] = React.useState(expensesData);

    const [tempFlyingHours, setTempFlyingHours] = React.useState<FlyingHours[]>([]);
    const [updateFlyingHours, setUpdateFlyingHours] = React.useState<FlyingHours[]>([]);
    const [updatedDeletedFlyingHours, setUpdatedDeletedFlyingHours] = React.useState<FlyingHours[]>([]);

    const [flyingHoursPaginationResult, setFlyingHoursPaginationResult] = React.useState<IPaginationResult<IFlyingHours>>();
    const [tempFlyingHoursPaginationResult, setTempFlyingHoursPaginationResult] = React.useState<IPaginationResult<IFlyingHours>>();
    const [isNoFlyDayConfirm, setIsNoFlyDayConfirm] = React.useState(false);

    //Contract Rates
    const [contractRatesPaginationResult, setContractRatesPaginationResult] = React.useState<IPaginationResult<IContractRates>>();

    //Recoil state
    const hiringContractsStateResult = useRecoilValue(hiringContractsState);

    //notes
    const [flightReportNoteList, setFlightReportNoteList] = React.useState<FlightReportNote[]>([]);

    const [ruleCode, setRuleCode] = React.useState("");
    const feature = "AviationReporting";
    const api = "CreateFlightReport";
    const getNotes = React.useCallback(async () => {
      try {
        let objIPagination: IPagination = {
          perPage: 20,
          page: 1,
        };

        let objIFilter: IFilter = {
          columnName: "expenseType",
          columnValue: "FlyingHours",
        };

        let objISearch: ISearch = {
          search: "",
          sortBy: "",
          sortOrder: "",
          filterBy: objIFilter,
          pagination: objIPagination,
        };
        const response = await notesService.getNotes(objISearch, flightReport?.flightReportId);
        setFlightReportNoteList(response.data.data);
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    }, [flightReport?.flightReportId]);

    React.useEffect(() => {
      if (flightReport?.flightReportId)
        getNotes();
    }, [getNotes, flightReport?.flightReportId]); // Include getNotes in the dependency array

    React.useEffect(() => {
      if (flightReport?.flightReportId === "") {
        setIsUpdate(false);
        setIsView(false);
        setIsEdit(true);
      } else {
        (async () => {
          getOnRender();
        })();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flightReport]);

    React.useEffect(() => {
      validationEnginService
        .getRules(feature, api, auth.user?.access_token as string)
        .then((response: any) => {
          setRuleCode(response.frontEndCode);
        })
        .catch((e: Error) => {
          console.log("Error", e);
        });
    }, [ruleCode, auth.user?.access_token]); // Empty dependency array to run the effect only once when the component mounts


    async function getOnRender() {
      setIsLoading(true);
      await getContractRates();

      if(!isNoFlyDayConfirm)
        await getFlyingHours();

      await getExpenses();
    }

    //#region "Flight report"

    function onFormValuesChange(value: FlightReport) {
      if (value != null && value !== undefined) {
        if (value.flightReportDate && value.corporateRegionId) setIsUpdate(true);
        else setIsUpdate(false);

        setUpdateFlightReport(value);
      }
    }

    function onChangeNoFlyDay() {
      setShowNoFlyDayModal(true);
      setIsFlyingHoursEdit(false);
    }

    async function onSubmit() {
      if (handleChildDataValidity(validate(ruleCode, "btnSave"))?.length > 0)
        return;

      setIsLoading(true);

      if (updateFlightReport?.flightReportId > 0) {
        updateFlightReport.updateTimestamp = moment(new Date()).format(
          "yyyy-MM-DD"
        );

        updateFlightReport.updateUserId = auth?.user?.profile?.name ?? "wildfire";  

        await FlightReportService.update(updateFlightReport as FlightReport, auth.user?.access_token as string)
          .then(async (response: any) => {
            var vendorResult = {name: updateFlightReport?.vendorName, financeVendorId:updateFlightReport?.financeVendorId}

            //Get corporate region
            var corporateRegion = getCorporateRegion(
              corporateRegionPaginationResult,
              response.data.data.corporateRegionId
            );

            //Get contract registration
            if (response.data.data.contractRegistrationId)
              var contractRegistrationObj = getContractRegistration(
                hiringContractsStateResult,
                response.data.data.contractRegistrationId
              );

            //Get flying registration
            if (response.data.data.flyingRegistrationId)
              var flyingRegistrationObj = getContractRegistration(
                hiringContractsStateResult,
                response.data.data.flyingRegistrationId
              );

            //response.data.data.flightReportId = updateFlightReport.flightReportId;
            setUpdateFlightReport(
              new FlightReport({
                ...response.data.data,
                corporateRegion: corporateRegion,
                vendor: vendorResult,
                contractRegistration: contractRegistrationObj,
                flyingRegistration: flyingRegistrationObj,
              })
            );

            //pass update value to parent to FlightReportDetail
            onUpdate(updateFlightReport);

            setCalloutVariant(CalloutVariant.success);
            setNotificationMessage("Flight Report summary saved!");
            setShowNotificationMessage(true);
            setIsEdit(false);
            setIsView(true);

            setIsLoading(false);
          })
          .catch((error) => {
            setError(error);
          });
      } else {
        if (updateFlightReport) {
          updateFlightReport.createTimestamp = moment(new Date()).format(
            "yyyy-MM-DD"
          );

          updateFlightReport.createUserId = auth?.user?.profile?.name ?? "wildfire";  

          await FlightReportService.create(updateFlightReport, auth.user?.access_token as string)
            .then(async (response: any) => {
              if (response) {

                var createdFlightReport = new FlightReport({
                  ...updateFlightReport,
                  flightReportId: response.data.data?.flightReportId,
                  status: response.data.data?.status,
                });
                
                setUpdateFlightReport(new FlightReport({ ...createdFlightReport }));
                //pass update value to parent to FlightReportDetail
                onUpdate(createdFlightReport);

                //send user notification
                setCalloutVariant(CalloutVariant.success);
                setNotificationMessage("Flight Report created!");
                setShowNotificationMessage(true);
                setIsEdit(false);
                setIsView(true);
                
                //Flying hours
                setIsFlyingHoursEdit(false);

                setIsLoading(false);
              }
            })
            .catch((error) => {
              setError(error);
            });
        }
      }

      //No fly day functionality
      if(isNoFlyDayConfirm)
      {
        if(updateFlyingHours)
        {
          updateFlyingHours.forEach((record) => {record.deleted = true });

          //Call flying hours createUpdate API
          await FlyingHoursService.createUpdate(updateFlyingHours, flightReport?.flightReportId)
              .then(async (response: any) => {
                if (response) {
                  //Remove flying hours array values
                  setUpdateFlyingHours([]);
                  setTempFlyingHours([]);
                }
              })
              .catch((error) => {
                setError(error);
              });
        }

        setIsNoFlyDayConfirm(false);
      }
    }

    function onDiscardChanges() {
      setUpdateFlightReport(new FlightReport({ ...tempFlightReport }));
      onUpdate(tempFlightReport);

      setIsUpdate(false);
      setShowModal(false);
      setIsNoFlyDayConfirm(false);
      setIsFlyingHoursDelete(false);
    }

    function onCancel() {
      setTempFlightReport(new FlightReport(tempFlightReport));
      onUpdate(tempFlightReport);

      // if(updateFlyingHours.length <= 0 && tempFlyingHours.length > 0)
      //   setUpdateFlyingHours(tempFlyingHours);

      setUpdateFlyingHours(tempFlyingHours);
      setUpdatedDeletedFlyingHours(tempFlyingHours);

      setShowModal(false);
      setIsEdit(false);
      setIsView(true);
    }

    //No fly day functionality
    function onNoFlyDayConfirmChanges() {
      updateFlightReport.meterStart = null;
      updateFlightReport.meterFinish = null;
      updateFlightReport.pointOfDeparture = "";

      setUpdateFlightReport(new FlightReport({ ...updateFlightReport }));
      onUpdate(updateFlightReport);

     if(flyingHoursPaginationResult)
     {
        flyingHoursPaginationResult.data.length = 0;
        setFlyingHoursPaginationResult({...flyingHoursPaginationResult});
        //set constant for not receall get flying hours
        setIsNoFlyDayConfirm(true);
     }

      setShowNoFlyDayModal(false);
      setIsUpdate(true);
    }

    function onNoFlyDayCancel() {
      //Flight report
      setUpdateFlightReport(new FlightReport({ ...tempFlightReport }));
      onUpdate(tempFlightReport);

      //Flying hours
      if(tempFlyingHoursPaginationResult)
        setFlyingHoursPaginationResult({...tempFlyingHoursPaginationResult});

      setIsNoFlyDayConfirm(false);
      setShowNoFlyDayModal(false);
      setIsEdit(true);
      setIsView(false);
    }

    //#endregion "Flight report"

    // #region "Flying hours"

    function onFlyingHoursValuesChange(value: Array<FlyingHours>) {
      if (value != null && value !== undefined) {
        setIsFlyingHoursUpdate(true);

        //Delete operation
        if(value.find(x=>x.isChecked))
        {
          setTotalFlyingHoursRowSelected(value.filter(x=>x.isChecked).length.toString())
          setIsFlyingHoursRowSelected(true);
          //setIsFlyingHoursUpdate(false);
          setIsFlyingHoursDelete(true);
        }
        else{
          setUpdateFlyingHours(tempFlyingHours);
          setIsFlyingHoursRowSelected(false);
          setIsFlyingHoursDelete(false);
        }

        setUpdateFlyingHours(value);
        //Delete, save
        setUpdatedDeletedFlyingHours(value);
      }
    }

    //Flying hour add new row callback
    function onAddNewRow(response: boolean)
    {
      //setIsFlyingHoursRowSelected(true);
    }

    function onFlyingHoursDelete()
    {
      if (updateFlyingHours.length > 0) {
        //set delete modal popup visibility
        setShowFlyingHoursDeleteModal(false);

        //For delete flying hours
        setTotalFlyingHoursRowSelected("");
        setIsFlyingHoursRowSelected(false);
        setIsFlyingHoursDeleteMode(true);

        if(updateFlyingHours.find(x=>x.isChecked))
        {
          updateFlyingHours.forEach((record) => record.isChecked ? record.deleted = true : <></>);
        }
        //Delete, save
        if(updatedDeletedFlyingHours.find(x=>x.isChecked))
        {
          updatedDeletedFlyingHours.forEach((record) => record.isChecked ? record.deleted = true : <></>);
        }

        setUpdateFlyingHours([...updateFlyingHours.filter(x=> !x.deleted)]);
      }
    }

    //set validation error
    async function setError(error: any) {
      var validationError = "";

      if (axios.isAxiosError(error)) {
        if (error.response) {
          var validation = error.response.data as IValidationError;
          if (validation) {
            validation.validationResult?.forEach((record: any, index: any) => {
              if (record) validationError += "<p>" + record.message + "</p>";
            });
          }
          if (validation?.errorMessage){
            validationError = validationError + "<p>" + validation.errorMessage + "</p>";     
          }
        }
      } else {
        console.error("else", error);
      }

      setCalloutVariant(CalloutVariant.emergency);
      setNotificationMessage(validationError);
      setShowNotificationMessage(true);

      setIsLoading(false);
    }

    async function getCrewTypeByID(crewTypeId: string) {
      try {
        var response: any = await CrewTypeService.getById(crewTypeId);
        return response.data;
      } catch (Error) {
        console.log("Error", Error);
      }
    }

    async function getCostingActivityByID(id: string) {
      try {
        var response: any = await CostingActivityService.getById(id);
        return response.data;
      } catch (Error) {
        console.log("Error", Error);
      }
    }

    async function getRateTypeByID(id: string) {
      try {
        var response: any = await RateTypeService.getById(id);
        return response.data;
      } catch (Error) {
        console.log("Error", Error);
      }
    }

    async function getRateUnitByID(rateUnitId: string) {
      try {
        var response: any = await RateUnitService.getById(rateUnitId);
        return response.data;
      } catch (Error) {
        console.log("Error", Error);
      }
    }

    async function getFireNumberByID(fireNumberId: string) {
      try {
        var response: any = await FireNumberService.getById(fireNumberId);
        if (response.status) return response.data.data;
      } catch (Error) {
        console.log("Error", Error);
      }
    }

    async function getContractRates() {
      await HiringContractService.getRates(
        flightReport?.contractRegistration as IHiringContract,
        flightReport?.flightReportDate
      )
        .then((response: any) => {
          if (response.data) {
            setContractRatesPaginationResult(response.data);
          }
        })
        .catch((e: Error) => {
          setIsLoading(false);
        });
    }

    async function getFlyingHours() {
      try{
        let objIPagination: IPagination = {
          perPage: 20,
          page: 1,
        };
  
        let objIFilter: IFilter = {
          columnName: "expenseType",
          columnValue: "FlyingHours",
        };
  
        let objISearch: ISearch = {
          search: "",
          sortBy: "",
          sortOrder: "",
          filterBy: objIFilter,
          pagination: objIPagination,
        };
  
        await FlyingHoursService.getSearch(objISearch, flightReport.flightReportId)
          .then(async (response: any) => {
            if (response.data.data) {

              //Bind data based on ID
              response.data.data?.forEach(async function (record: any, index: any) {
                if (record.fireNumberId) {
                  var fireNumber = await getFireNumberByID(record.fireNumberId);
                  record.fireNumberEntity = fireNumber ?? undefined;
                  record.fireNumber = fireNumber?.fireNumber;
                }
                if (record.crewTypeId) {
                  var crewType = await getCrewTypeByID(record.crewTypeId);
                  record.crewTypeEntity = crewType ?? undefined;
                }
                if (record.costingActivityId) {
                  var costingActivity = await getCostingActivityByID(
                    record.costingActivityId
                  );
                  record.costingActivityEntity = costingActivity ?? undefined;
                  record.costingActivityCode = costingActivity?.code;
                }
                if (record.rateTypeId) {
                  var rateType = await getRateTypeByID(record.rateTypeId);
                  record.rateTypeEntity = rateType ?? undefined;
                }
                if (record.rateUnitId) {
                  var rateUnit = await getRateUnitByID(record.rateUnitId);
                  record.rateUnitEntity = rateUnit ?? undefined;
                }
  
                //Drop down list bind
                record.fireNumberList = fireNumberOptions ?? undefined;
                record.crewTypeList = crewTypeOptions ?? undefined;
                record.costingActivityList = costingActivityOptions ?? undefined;
                record.rateTypeList = rateTypeOptions ?? undefined;
                record.rateUnitList = rateUnitOptions ?? undefined;
  
                //1GX coding - financial
                record.internalOrderList = internalOrderOptions ?? undefined;
                record.costCenterList = costCenterOptions ?? undefined;
                record.fundList = fundOptions ?? undefined;
                record.accountList = accountOptions ?? undefined;
  
                setFlyingHoursPaginationResult({ ...response.data });
                setTempFlyingHoursPaginationResult({...response.data })
              });

              //Set update flying hours records
              //setUpdateFlyingHours(response.data.data);
              //setTempFlyingHours({...response.data.data});
  
              //console.log("setUpdateFlyingHours", response.data.data)
              setUpdateFlyingHours([...response.data.data]) //copy array by value
              setTempFlyingHours([...response.data.data]) //override state with new values
              setUpdatedDeletedFlyingHours([...response.data.data]); //For delete, save functionality
            }
            else if(response.data.data === null)
            {
              setFlyingHoursPaginationResult({ ...response.data });
              setTempFlyingHoursPaginationResult({...response.data })

              setUpdateFlyingHours([]) //copy array by value
              setTempFlyingHours([]) //override state with new values
              setUpdatedDeletedFlyingHours([]); //For delete, save functionality
            }
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      }
      catch(error)
      {
        console.log(error);
      }
    }

    async function onFlyingHoursSubmit() {
      setIsLoading(true);

      if (updatedDeletedFlyingHours.length > 0 && flightReport?.flightReportId) {
        await FlyingHoursService.createUpdate(
          updatedDeletedFlyingHours,
          flightReport?.flightReportId,
          auth.user?.access_token as string
        )
          .then(async (response: any) => {
            await getFlyingHours();

            //set delete modal popup visibility
            setShowFlyingHoursDeleteModal(false);

            setCalloutVariant(CalloutVariant.success);
            setNotificationMessage("Flying hours summary saved!");
            setShowNotificationMessage(true);
            setIsFlyingHoursEdit(false);

            //For delete flying hours
            setTotalFlyingHoursRowSelected("");
            setIsFlyingHoursRowSelected(false);

            setIsLoading(false);
          })
          .catch((error) => {
            setError(error);
          });
      }
    }

    //#endregion "Flying hours"

    // #region "Expense"

    async function getExpenses() {
      try {
        await ExpenseService.getById(flightReport?.flightReportId)
        .then(async (response: any) => {

          if (response.data.data) {
            response.data.data.flightCrewEligibility.map(async (record: any) => {
              if (!record.createUserId) {
                record.createUserId = auth?.user?.profile?.name ?? "wildfire";
              }
            });

            response.data?.data?.expenseDetails?.forEach(async function (record: any, index: any) {
              //Drop down list bind
              record.fireNumberList = fireNumberOptions ?? undefined;
              record.crewTypeList = crewTypeOptions ?? undefined;
              record.costingActivityList = costingActivityOptions ?? undefined;
              record.rateTypeList = rateTypeOptions ?? undefined;
              record.rateUnitList = rateUnitOptions ?? undefined;

              //1GX coding - financial
              record.internalOrderList = internalOrderOptions ?? undefined;
              record.costCenterList = costCenterOptions ?? undefined;
              record.fundList = fundOptions ?? undefined;
              record.accountList = accountOptions ?? undefined;
            });

            if(response.data?.data?.flightFuelUsage)
            {
              var flightFuelUsageObj = response.data?.data?.flightFuelUsage;
              flightFuelUsageObj.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
              flightFuelUsageObj.updateTimestamp = moment(new Date()).format("yyyy-MM-DD");
            }

            let flightExpensesData = new FlightExpenses(response.data.data);

            setExpenseData(flightExpensesData);
            setUpdateFlightExpenses(flightExpensesData);
            setIsLoading(false);
          }
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
        
      } catch (e) {
        setIsLoading(false);
        console.log("Error", e);
        throw e; // Re-throw the error if needed
      }
    }

    //Flying Expenses
    function onFlightExpensesValuesChange(value: FlightExpenses) {
      if (value != null && value !== undefined) {
        setIsFlightExpensesUpdate(true);
        setUpdateFlightExpenses(value);
      }
    }

    async function onFlightExpensesSubmit() {
      setIsLoading(true);
      
      if (updateFlightExpenses) {
        await ExpenseService.createUpdate(
          updateFlightExpenses,
          flightReport?.flightReportId,
          auth.user?.access_token as string
        )
          .then(async (response: any) => {
            if (response.status === 401) {
              // Redirect to the login page using React Router
              navigate('/');
            }
            setCalloutVariant(CalloutVariant.success);
            try {
              await getExpenses();
          
              // Code inside this block will only run after getExpenses is completed
              setNotificationMessage("Flight expenses saved!");
              setShowNotificationMessage(true);
              setIsFlightExpensesEdit(false);
              setIsLoading(false);
          } catch (error) {
              // Handle errors from getExpenses if needed
              console.error("Error in getExpenses:", error);
          }
          })
          .catch((error) => {
            setError(error);
          });
      }
    }

    //#endregion "Expense"

    //#region "Notes"

    function onNotesChange() {
      getNotes();
    }

    //#endregion "Notes"

    const [isSectionOpen, setIsSectionOpen] = React.useState(true);

    const toggleSection = () => {
      setIsSectionOpen(!isSectionOpen);
    };

    return (
      <>
        <PageLoader visible={loading} />

        <div className="left-section">
          <div
            className={`sliding-section ${isSectionOpen ? "open" : "closed"}`}
          >
            <div className="toggle-button" onClick={toggleSection}>
              {isSectionOpen ? (
                <GoAIcon type="arrow-back" />
              ) : (
                <GoAIcon type="arrow-forward" />
              )}
            </div>
            <div className="left-section-content new-layout-content">
              <div>
                <GoADetails heading="Flight report summary" open={true}>
                  <div className={`${isSectionOpen ? "flex-end" : "flex-right"}`}>  
                  {/* <div style={{ display: "flex", justifyContent: "flex-end" }}> */}
                    {isView && auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
                      <GoAButton
                        type="tertiary"
                        trailingIcon="pencil"
                        onClick={() => {
                          setIsUpdate(false);
                          setIsView(false);
                          setIsEdit(true);
                          onEdit(true);
                        }}
                      >
                        Edit
                      </GoAButton>
                    </div>}

                    {/* Edit functionality button */}
                    
                    {isEdit && auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
                      <GoAButton
                        type="tertiary"
                        trailingIcon="save"
                        disabled={!isChildDataValid} //{!isUpdate || !isChildDataValid}
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        Save
                      </GoAButton>
                    
                      <GoAButton
                        type="tertiary"
                        onClick={() => {
                          if (flightReport?.flightReportId !== 0) {
                            if (isUpdate) {
                              setShowModal(true);
                            } else {
                              setIsEdit(false);
                              setIsView(true);
                              onEdit(false);
                            }
                          } else {
                            navigate("/dashboard");
                          }
                        }}
                      >
                        Cancel
                      </GoAButton>
                    </div>}
                  </div>

                  {isView ? (
                    <ViewFlightReportSummary
                      flightReport={updateFlightReport}
                    />
                  ) : (
                    <></>
                  )}

                  {isEdit ? (
                    <EditFlightReportSummary
                      ruleCode={ruleCode}
                      flightReportList={flyingHoursPaginationResult}
                      flightReport={updateFlightReport}
                      corporateRegionPaginationResult={
                        corporateRegionPaginationResult
                      }
                      onChange={onFormValuesChange}
                      onChildDataValidityChange={handleChildDataValidity}
                      onChangeNoFlyDay={onChangeNoFlyDay}
                    />
                  ) : (
                    <></>
                  )}
                </GoADetails>

                <GoADetails heading="Hiring details" open={true}>
                  {isView || isEdit ? (
                    <ViewFlightReportHiringDetails
                      flightReport={updateFlightReport}
                    />
                  ) : (
                    <></>
                  )}
                </GoADetails>
              </div>
            </div>
          </div>
        </div>

        {/* Right section */}
        <div className="right-section right-section-content new-layout-content">
          <GoATabs>
            {/* #region "Flying hours section" */}

            <GoATab heading="Flying hours">
                <div className="right paddingRight20px">
                  {!isFlyingHoursEdit && updateFlightReport.flightReportId > 0 && auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
                    <GoAButton
                      size="compact"
                      type="tertiary"
                      disabled={updateFlightReport.noFlyDay}
                      onClick={() => {
                        setIsFlyingHoursEdit(true);
                        setIsFlyingHoursUpdate(false);
                      }}
                    >
                      Edit
                    </GoAButton>
                  </div>}
                </div>

              {!isFlyingHoursEdit ? (
                <div>
                  <ViewFlyingDetail
                    flyingHoursPaginationResult={flyingHoursPaginationResult}
                  />
                </div>
              ) : (
                <></>
              )}

              {isFlyingHoursEdit ? (
                <div className="right paddingRight20px">
                  {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
                    <GoAButton
                      size="compact"
                      type="tertiary"
                      disabled={!isFlyingHoursUpdate || !isChildDataValid}
                      onClick={() => onFlyingHoursSubmit()}
                    >
                      Save
                    </GoAButton>
                    <GoAButton
                      size="compact"
                      type="tertiary"
                      onClick={() => {
                        tempFlyingHours.forEach((record) => {record.isChecked =false; record.deleted = false });
                        setUpdateFlyingHours([...tempFlyingHours]);
                        setUpdatedDeletedFlyingHours([...tempFlyingHours]);
                        
                        setIsFlyingHoursEdit(false);
                        
                        //For delete flying hours
                        setTotalFlyingHoursRowSelected("");
                        setIsFlyingHoursRowSelected(false);
                      }}
                    >
                      Cancel
                    </GoAButton>
                  </div>}
                </div>
              ) : (
                <></>
              )}

              {/* //#region Delete */}
              {isFlyingHoursEdit && isFlyingHoursRowSelected ? (
                <div className="flyingHoursDelete">
                  <div className="row accordion">
                    <div className="form-row width100 setBackground">
                      <div className="col-50 white-color">
                        {`${totalFlyingHoursRowSelected} selected`}
                      </div>
                      <div className="col-50">
                        <div className="right">
                          {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
                            <GoAButton
                              size="compact"
                              type="secondary"
                              trailingIcon="trash"
                              disabled={!isFlyingHoursDelete || !isChildDataValid}
                              onClick={() => {
                                setShowFlyingHoursDeleteModal(true);
                              }}
                            >
                              Delete
                            </GoAButton>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            {/* //#endregion Delete */}

              {isFlyingHoursEdit ? (
                <EditFlyingDetail
                  flightReport={updateFlightReport}
                  arrFlyingHours={updateFlyingHours}
                  contractRatesPaginationResult={contractRatesPaginationResult}
                  fireNumberOptions={fireNumberOptions}
                  crewTypeOptions={crewTypeOptions}
                  costingActivityOptions={costingActivityOptions}
                  rateTypeOptions={rateTypeOptions}
                  rateUnitOptions={rateUnitOptions}
                  internalOrderOptions = {internalOrderOptions} costCenterOptions={costCenterOptions} fundOptions = {fundOptions} accountOptions = {accountOptions}
                  onChange={onFlyingHoursValuesChange}
                  ruleCode={ruleCode}
                  onChildDataValidityChange={handleChildDataValidity}
                  onAddNewRow={onAddNewRow}
                />
              ) : (
                <></>
              )}
            </GoATab>

            {/* #endregion "Flying hours section" */}

            {/* #region "Expense section" */}

            <GoATab heading="Expenses">
              {!isFlightExpensesEdit && expensesData ? (
                <div className="right paddingRight20px">
                  {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
                    <GoAButton
                      size="compact"
                      type="tertiary"
                      onClick={() => {
                        setIsFlightExpensesEdit(true);
                        setIsFlightExpensesUpdate(false);
                      }}
                    >
                      Edit
                    </GoAButton>
                  </div>}
                </div>
              ) : (
                <></>
              )}

              {isFlightExpensesEdit ? (
                <div className="right paddingRight20px">
                  {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
                    <GoAButton
                      size="compact"
                      type="tertiary"
                      disabled={!isFlightExpensesUpdate || !isChildDataValid}
                      onClick={() => onFlightExpensesSubmit()}
                    >
                      Save
                    </GoAButton>
                    <GoAButton
                      size="compact"
                      type="tertiary"
                      onClick={async () => {
                        await getExpenses();
                        setIsFlightExpensesEdit(false);
                      }}
                    >
                      Cancel
                    </GoAButton>
                  </div>}
                </div>
              ) : (
                <></>
              )}
              <div className="expense-section white-background">
                {isFlightExpensesEdit ? (
                  <EditExpenseDetails flightExpenses={updateFlightExpenses} contractRatesPaginationResult={contractRatesPaginationResult} flightReport={updateFlightReport}
                    fireNumberOptions={fireNumberOptions} crewTypeOptions={crewTypeOptions} costingActivityOptions={costingActivityOptions}
                    internalOrderOptions = {internalOrderOptions} costCenterOptions={costCenterOptions} fundOptions = {fundOptions} accountOptions = {accountOptions}
                    onChange={onFlightExpensesValuesChange}
                     />
                ) : (
                  <div className="paddingTop20px">
                    <ViewExpenseDetails expensesData={expensesData} />
                  </div>
                )}
              </div>
            </GoATab>

            <GoATab heading={<span>Notes <GoABadge type="midtone" content={flightReportNoteList ? flightReportNoteList.length.toString() : '0'} /></span>}>
              <FlightReportNotes onChange={onNotesChange} flightReportNoteList={flightReportNoteList} flightReportId={flightReport?.flightReportId}></FlightReportNotes>
            </GoATab>

            {/* #endregion "Expense section" */}

          </GoATabs>
        </div>
        {/* Right section end */}

        <GoAModal
          heading={modalHeading}
          calloutVariant={calloutVariant}
          open={showNotificationMessage}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton onClick={() => setShowNotificationMessage(false)}>
                Okay
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <div dangerouslySetInnerHTML={{ __html: notificationMessage }} />
        </GoAModal>

        <GoAModal
          heading="You have unsaved changes"
          open={showModal}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton onClick={() => onCancel()}>Cancel</GoAButton>
              <GoAButton onClick={() => onDiscardChanges()}>
                Discard changes
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <p>Are you sure you want to discard changes?</p>
        </GoAModal>

        {/* No fly day pop up dialog box */}
        <GoAModal
          heading="No Fly Day Selected"
          open={showNoFlyDayModal}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton type="secondary" onClick={() => onNoFlyDayCancel()}>No, cancel</GoAButton>
              <GoAButton onClick={() => onNoFlyDayConfirmChanges()}>
                Yes, confirm
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <div className="divUlLi">
            <p>The following values will be removed:</p>
            <p>
              <ul className="b">
                <li>Flying Hours ({updateFlyingHours.length} lines)</li>
                <li>Meter Start, Finish and Difference </li>
                <li>Departure Location</li>
              </ul>
            </p>
          </div>

        </GoAModal>

        {/* Flying hour delete pop up dialog box */}
        <GoAModal
          heading={`${totalFlyingHoursRowSelected} line of flying hours will be deleted.`}
          open={showFlyingHoursDeleteModal}
          actions={
            <GoAButtonGroup alignment="end">
              <GoAButton onClick={() => setShowFlyingHoursDeleteModal(false)}>No, cancel</GoAButton>
              <GoAButton onClick={() => onFlyingHoursDelete()}>
                Yes, delete
              </GoAButton>
            </GoAButtonGroup>
          }
        >
          <p>This action cannot be undone. Are you sure?</p>
        </GoAModal>
      </>
    );
  };

export default FlightReportSummary;
