import * as React from 'react';
import { GoAButton, GoAContainer, GoAFormItem, GoATextArea } from '@abgov/react-components';
import { FlightReportNote } from '../../model/IFlightReportNote';
import { convertUtcToMst, formatDateString } from '../../utils/dateHandler';
import { notesService } from '../../services/notes/NotesService';
import { FunctionComponent, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { AuthenticationService } from '../../services/authentication/AuthenticationService';

interface IFlightReportNotesProps {
  flightReportId: number;
  flightReportNoteList: FlightReportNote[];
  onChange: any;
}

const FlightReportNotes: FunctionComponent<IFlightReportNotesProps> = ({ onChange, flightReportNoteList, flightReportId, ...props }) => {
  const auth = useAuth();
  
  const [newNote, setNewNote] = useState('');
  const [sortBy, setSortBy] = useState('newest'); // Initial sort order

  const addNoteClick = async () => {
    const reportNote = new FlightReportNote();
    reportNote.note = newNote;
    reportNote.createUserId = auth?.user?.profile?.name ?? "wildfire";  

    try {
      // Step 1: Create a new note
      await notesService.createNote(reportNote, flightReportId);

      // Step 2:notify the parent to Fetch the updated notes
      await onChange();

      // Step 3: Clear the input field after adding the note
      setNewNote('');
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  // Sort the array by createTimestamp in descending order before rendering
  const sortedNotes = flightReportNoteList?.slice().sort((a, b) => {
    // Sort the data based on the selected option
    if (sortBy === 'newest') {
      return new Date(b.createTimestamp).getTime() - new Date(a.createTimestamp).getTime(); // Assuming data has a 'date' property
    } else {
      return new Date(a.createTimestamp).getTime() - new Date(b.createTimestamp).getTime();
    }
  });

  const handleToggle = () => {
    // Toggle between 'newest' and 'oldest'
    setSortBy((prevSortBy) => (prevSortBy === 'newest' ? 'oldest' : 'newest'));
  };

   
  return (
    <>
      <div className="notes-section white-background">
        <GoAContainer padding="compact">
          <div className="note-container">
            <div className='edit-note'>
              <GoAFormItem label="Add a note">
                <GoATextArea
                  value={newNote}
                  name="note"
                  rows={3}
                  width="100%"
                  countBy="character"
                  maxCount={1200}
                  onChange={(name, value) => setNewNote(value)}
                  onKeyPress={(name, value) => setNewNote(value)}
                />
              </GoAFormItem>
            </div>

            {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) && <div>
              <GoAButton type="primary" onClick={addNoteClick} disabled={!flightReportId || newNote?.length>1200}>
                Add note
              </GoAButton>
            </div>}

          </div>
        </GoAContainer>
        <div className='sort-note-toggle'>
          <GoAButton
            type="tertiary"
            trailingIcon={sortBy === 'newest' ? "arrow-down" : "arrow-up"}
            onClick={handleToggle}
          >
            {sortBy === 'newest' ? 'Newest first' : 'Oldest first'}
          </GoAButton>
        </div>
        {sortedNotes?.map((note, index) => (
          <GoAContainer key={index} padding="compact">
            <div className="note-container">
              <div className="note-title-wrapper">
                <div className="note-title">{note.createUserId}</div>
                <div>On {formatDateString(convertUtcToMst(note.createTimestamp))} </div>
              </div>
              <div  className="note-content">{note.note}</div>
            </div>
          </GoAContainer>
        ))}
      </div>
    </>
  );
};

export default FlightReportNotes;
