import { GoAFormItem } from '@abgov/react-components';
import * as React from 'react';
import { FlightReport} from '../../model/IFlightReport';

interface IViewFlightReportSummaryProps {
    flightReport:FlightReport,
    visible?:boolean
}

const ViewFlightReportSummary: React.FunctionComponent<IViewFlightReportSummaryProps> = ({flightReport,visible,...props}) => {
  return (
    <>
      <div>
        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Flight Date">
              <label>
                {flightReport?.flightReportDate}
              </label>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="AO-02 Number">
              <label>{flightReport?.ao02Number}</label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Contract Registration">
              <label>
                {flightReport?.contractRegistration?.aircraftRegistration}
              </label>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="Flying Registration">
              <label>
                {flightReport?.flyingRegistration?.aircraftRegistration}
              </label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Meter Unserviceable?">
              <label>{flightReport?.meterUS === true ? "Yes" : "No"}</label>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="No Fly Day?">
              <label>{flightReport?.noFlyDay === true ? "Yes" : "No"}</label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Meter Start">
              <label>
                {flightReport?.meterStart ? flightReport?.meterStart : "-"}
              </label>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="Meter Finish">
              <label>
                {flightReport?.meterFinish ? flightReport?.meterFinish : "-"}
              </label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Meter Difference">
              <label>
                {flightReport?.meterStart && flightReport?.meterFinish
                  ? (
                      flightReport?.meterFinish - flightReport?.meterStart
                    ).toFixed(1)
                  : "-"}
              </label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Forest Area">
              <label>
                {flightReport?.corporateRegion
                  ? flightReport?.corporateRegion.name
                  : ""}
              </label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Departure Location">
              <label>{flightReport?.pointOfDeparture ? flightReport?.pointOfDeparture : "-"}</label>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="Down For Night Location">
              <label>{flightReport?.downForNightLocation}</label>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Unserviceable Report?">
              <label>
                <label>
                  {flightReport?.unserviceableReport === true ? "Yes" : "No"}
                </label>
              </label>
            </GoAFormItem>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewFlightReportSummary;
