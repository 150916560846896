import { httpDomainServiceApim } from "../../utils/domainServiceAPIMConfig";
import { IRateType } from "../../model/domainServices/IRateType";
import { IArrayOption } from "../../model/IArrayOption";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch, strToken:string = "") => {
    const params = JSON.stringify({
      serviceName:objISearch.serviceName,
      search: objISearch.search,
      sortBy: objISearch.sortBy,
      sortOrder: objISearch.sortOrder,
      filterBy: {
        negotiated:objISearch.filterBy?.negotiated,
        columnName: objISearch.filterBy?.columnName,
        columnValue: objISearch.filterBy?.columnValue
      },
      paginationInfo: {
        perPage: objISearch.pagination.perPage,
        page: objISearch.pagination.page,
      },
    });
  
    const data = await httpDomainServiceApim.post<IPaginationResult<IRateType>>(
      "/rate_types/get",
      params,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
    
    return data;
  };

  const getById = async (id: string, strToken:string = "") => {
    const data = await httpDomainServiceApim.post<IRateType>(
      `/rate_types/get/${id}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
  
    return data;
  };

  const getByOracleId = async (oracleId: number, strToken:string = "") => {
    const data = await httpDomainServiceApim.post<IRateType>(
      `/rate_types/get/${oracleId}`,
      "",
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
  
    return data;
  };
  
  export const RateTypeService = {
    getSearch,
    getById,
    getByOracleId
  };

  export const getRateType = (rateTypeStateResult:IPaginationResult<IRateType>, rateTypeId:string) => {
    if(rateTypeStateResult != null)
    {
      const result = rateTypeStateResult.data.find(value => value.rateTypeId === rateTypeId);
      return result;
    }
    else{
      return undefined;
    }
  }

  export const bindRateTypeDropdown = (rateTypePaginationResult:IPaginationResult<IRateType>): IArrayOption[] => {
    const options = [];
    
    if(rateTypePaginationResult.data)
    {
      for (let i = 0; i < rateTypePaginationResult.data.length; i++) {
        let obj :IArrayOption = {label: rateTypePaginationResult.data[i].type, value: rateTypePaginationResult.data[i].rateTypeId, oracleId:rateTypePaginationResult.data[i].oracleId};
        options.push(obj);
      }
    }

    return options;
  }