import { GoAButton, GoAFormItem, GoAInputText, GoARadioGroup, GoARadioItem, GoATable } from '@abgov/react-components';
import * as React from 'react';
import Select from "react-select";
import { ExpenseDetail, FlightCrewEligibility, FlightExpenses, FlightFuelUsage } from '../../model/IFlightExpense';
import { FlyingDetailCRUD } from '../../operations/FlyingDetailCRUD';
import { IContractRates } from '../../model/HiringContract/IContractRates';
import { IPaginationResult } from '../../model/search/IPaginationResult';
import PageLoader from '../../components/PageLoader';
import { bindCostingActivityDropdown } from '../../services/domainServices/CostingActivityService';
import { RateService } from '../../services/domainServices/RateService';
import { ISearch } from '../../model/search/ISearch';
import { IPagination } from '../../model/search/IPagination';
import { IRate } from '../../model/domainServices/IRate';
import { useCallback, useEffect, useState } from 'react';
import { FlightReport } from '../../model/IFlightReport';
import { ExpenseDetailCRUD } from '../../operations/ExpenseDetailCRUD';
import { bindRateTypeDropdown } from '../../services/domainServices/RateTypeService';
import { bindRateUnitDropdown, RateUnitService } from '../../services/domainServices/RateUnitService';
import { FlightReportCRUD } from '../../operations/FlightReportCRUD';
import ContractType from '../../model/enum/ContractType';
import { getContractRates } from '../../services/ContractRateService';
import { IArrayOption } from '../../model/IArrayOption';
import moment from 'moment';
import { bindInternalOrderDropdown } from '../../services/1GXCoding/InternalOrderService';
import { bindCostCenterDropdown } from '../../services/1GXCoding/CostCenterService';
import { bindFundDropdown } from '../../services/1GXCoding/FundService';
import { bindAccountDropdown } from '../../services/1GXCoding/AccountService';
import { bindFireNumberDropdown } from '../../services/domainServices/FireNumberService';
import { useAuth } from 'react-oidc-context';
import ActionEvents from '../../model/enum/ActionEvents';

interface IEditExpenseDetailsProps {
  flightExpenses: FlightExpenses | undefined;
  flightReport: FlightReport;
  contractRatesPaginationResult: IPaginationResult<IContractRates> | undefined;
  fireNumberOptions: IArrayOption[];
  crewTypeOptions: IArrayOption[];
  costingActivityOptions: IArrayOption[];
  internalOrderOptions:IArrayOption[];
  costCenterOptions:IArrayOption[];
  fundOptions:IArrayOption[];
  accountOptions:IArrayOption[];
  onChange: (value: FlightExpenses) => void;
}


const providerOptions = [
  { label: 'GOA Provided', value: 'GOA Provided' },
  { label: 'Vendor Provided', value: 'Vendor Provided' },
  { label: 'Not Applicable', value: 'Not Applicable' },
];

const rolesOptions = [
  { label: 'Pilot', value: 'Pilot' },
  { label: 'Engineer', value: 'Engineer' },
];

const rateTypeLabels = [
  { fieldName: "breakfast", label: "Crew Exp - Breakfast" },
  { fieldName: "lunch", label: "Crew Exp - Lunch" },
  { fieldName: "dinner", label: "Crew Exp - Dinner" },
  { fieldName: "lodging", label: "Accommodation" },
  { fieldName: "vehicle", label: "Vehicle Rental" }
];


const EditExpenseDetails: React.FunctionComponent<IEditExpenseDetailsProps> = ({ flightExpenses, flightReport, contractRatesPaginationResult,
  fireNumberOptions, crewTypeOptions, costingActivityOptions, 
  internalOrderOptions, costCenterOptions, fundOptions, accountOptions,
  onChange, ...props }) => {
  
  const auth = useAuth();

  const [expenseRates, setExpenseRates] = useState<IPaginationResult<IRate> | null>(null);
  const [formValues, setFormValues] = React.useState(new FlightExpenses(flightExpenses));
  const [loading, setIsLoading] = React.useState(false);
  const [rateTypeOptions, setRateTypeOptions] = React.useState<IArrayOption[]>([]);
  const [rateUnitOptions, setRateUnitOptions] = React.useState<IArrayOption[]>([]);

  const [isCostingActivitySearch, setIsCostingActivitySearch] = React.useState(false);

  async function createAdditionalExpenseLongTerm(expenseType: string, numberOfItems: number, updatedFlightExpense: FlightExpenses)
  {
      const expense = new ExpenseDetail();
      var rateType, contractRates,rateUnit; 
      rateType = rateTypeOptions.find((t)=> t.label===expenseType);
      
      if(rateType)
      {
        expense.rateTypeId = rateType.value;
        expense.rateType = expenseType;
      }

      if(contractRatesPaginationResult)
      {
        contractRates  = getContractRates(contractRatesPaginationResult, rateType?.oracleId ?? 0);
      }
      
      // Check if there is already an existing expense of this type
      const existingExpense = formValues.expenseDetails.find((x: any) => x.rateTypeId === expense.rateTypeId)

      if (existingExpense) {
        Object.assign(expense, existingExpense);
        updatedFlightExpense.expenseDetails = updatedFlightExpense.expenseDetails.filter((item) => item !== existingExpense);
      }

      expense.noOfUnits = numberOfItems;
      
      if(contractRates)
      {
        rateUnit = await RateUnitService.getByOracleId(contractRates?.cruId ?? 0);
      }
      
      expense.rateUnitId = rateUnit ? rateUnit?.data?.rateUnitId : undefined;;
      expense.ratePerUnit = contractRates?.ratePerUnit ?? 0;
      expense.flightReportId = flightReport.flightReportId;
      expense.cost = expense.noOfUnits * expense.ratePerUnit;
      expense.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
      expense.createUserId = auth?.user?.profile?.name ?? "wildfire";
      expense.deleted = false;

      //Drop down list bind
      expense.fireNumberList = fireNumberOptions ?? undefined;
      expense.costingActivityList = costingActivityOptions ?? undefined;
      expense.rateTypeList = rateTypeOptions ?? undefined;
      expense.rateUnitList = rateUnitOptions ?? undefined;

      //1GX coding - financial
      expense.internalOrderList = internalOrderOptions ?? undefined;
      expense.costCenterList = costCenterOptions ?? undefined;
      expense.fundList = fundOptions ?? undefined;
      expense.accountList = accountOptions ?? undefined;

      return expense;
  }

  const createAdditionalExpense = useCallback((expenseType: string,
    numberOfItems: number,
    updatedFlightExpense: FlightExpenses,) => {

    if (numberOfItems > 0) {
      const expense = new ExpenseDetail();
      expense.rateTypeId = rateTypeOptions.find((x) => x.label === expenseType)?.value ?? '';
      expense.rateType = expenseType;

      // Check if there is already an existing expense of this type
      const existingExpense = formValues.expenseDetails.find((x: any) => x.rateTypeId === expense.rateTypeId)

      if (existingExpense) {
        Object.assign(expense, existingExpense);
        updatedFlightExpense.expenseDetails = updatedFlightExpense.expenseDetails.filter((item) => item !== existingExpense);
      }
      
      expense.noOfUnits = numberOfItems;
      const rate = expenseRates?.data.find((r: { rateTypeId: string | undefined }) => r.rateTypeId === expense.rateTypeId);
      expense.rateUnitId = rate?.rateUnitId;

      //check if rate per unit is modified
      if(expenseType === "Fuel" && formValues.expenseDetails.find(x => x.rateType === "Fuel" && x.ratePerUnit > 0 && x.ratePerUnit !== rate?.ratePerUnit))
      {
        expense.ratePerUnit = formValues?.expenseDetails.find(x => x.rateType === "Fuel")?.ratePerUnit as number;
      }
      else{
        expense.ratePerUnit = rate?.ratePerUnit ?? 0;
      }

      // Check if there is already an existing expense of this type
      if(existingExpense && existingExpense?.ratePerUnit !== expense.ratePerUnit)
      {
        expense.ratePerUnit = existingExpense?.ratePerUnit;
      }
      
      expense.flightReportId = flightReport.flightReportId;
      expense.cost = expense.noOfUnits * expense.ratePerUnit;
      expense.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
      expense.createUserId = auth?.user?.profile?.name ?? "wildfire";
      expense.deleted = false;

      //Drop down list bind
      expense.fireNumberList = fireNumberOptions ?? undefined;
      expense.costingActivityList = costingActivityOptions ?? undefined;
      expense.rateTypeList = rateTypeOptions ?? undefined;
      expense.rateUnitList = rateUnitOptions ?? undefined;

      //1GX coding - financial
      expense.internalOrderList = internalOrderOptions ?? undefined;
      expense.costCenterList = costCenterOptions ?? undefined;
      expense.fundList = fundOptions ?? undefined;
      expense.accountList = accountOptions ?? undefined;

      return expense;
    }

    // If no vendorProvided items, return null.
    return null;

  // eslint-disable-next-line
  }, [expenseRates?.data, flightReport.flightReportId, auth?.user?.profile?.name, formValues.expenseDetails]); // List dependencies here that affect the function

  useEffect(() => {
    const getDomainServiceData = async () => {
      try {
        //set contract type
        let hiringContracts = await getHiringContract();
        formValues.contractType = hiringContracts?.find(x => x.id === flightReport.contractRegistrationId)?.contractType ?? '';

        const rateTypesResponse = await ExpenseDetailCRUD.getRateTypes(flightReport.flightReportDate, '', 1000);
        if (rateTypesResponse) {
          const rateTypeResult = bindRateTypeDropdown(rateTypesResponse.data);
          setRateTypeOptions(rateTypeResult);
        }

        const rateUnitsResponse = await ExpenseDetailCRUD.getRateUnits(flightReport.flightReportDate, '', 1000);
        if (rateUnitsResponse) {
          const rateUnitResult = bindRateUnitDropdown(rateUnitsResponse.data);
          setRateUnitOptions(rateUnitResult);
          setIsLoading(false);
        }

        if (!expenseRates) {
          const rates = await getExpenseRate();
          if (rates) {
            setExpenseRates(rates);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    async function getHiringContract() {
      var aircraftDetails = await FlightReportCRUD.getHiringContract(
        flightReport?.flightReportDate
      );
  
      return aircraftDetails;
    }
    getDomainServiceData();

  }, [expenseRates, flightReport.flightReportDate,flightReport.contractRegistrationId,formValues]);

  useEffect(() => {

    const addRemoveBasingExpense = async () => {
      try {
        var rateType;
        
        if(formValues.contractType === ContractType.long)
        {
          if(!formValues.expenseDetails.find(x => x.rateType === "Basing" || x.rateType === "Basing Non-Core"))
          {
            //Basing
            if(contractRatesPaginationResult?.data.find(value => value.crtyId === 4))
            {
              rateType = "Basing";
            }
            //Basing Non-Core
            else{ 
              rateType = "Basing Non-Core";
            }

            const expense = await createAdditionalExpenseLongTerm(rateType, 1, formValues);
            if (expense) {
              formValues.expenseDetails.push(expense);
            }
          }

          setFormValues(formValues);
        }
        else if(formValues.contractType === ContractType.casual) {
          // Filter and set 'deleted' to true for items with rateType "Basing" or "Basing Non-Core"
          formValues.expenseDetails
            .filter((x) => x.rateType === "Basing" || x.rateType === "Basing Non-Core")
            .forEach((x) => (x.deleted = true));

            setFormValues(formValues);
        }

        //send on change to parent component
        onChange(formValues);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error as needed
      }

    };
    
    if (rateTypeOptions?.find(x => x.label === "Basing" || x.label === "Basing Non-Core") && expenseRates)
    {
      addRemoveBasingExpense();
    }
      // eslint-disable-next-line
  }, [rateTypeOptions]);


  async function onPropertyChangeFuelUsage(value: Partial<FlightFuelUsage>) {
    let newValue = new FlightFuelUsage({ ...formValues?.flightFuelUsage, ...value });
    let newFlightExpense = new FlightExpenses({ ...formValues, flightFuelUsage: newValue });
    var expense : any = undefined;

    const numberOfFuelItems = newFlightExpense.flightFuelUsage.provider === "Vendor Provided" ?
      parseFloat(newFlightExpense.flightFuelUsage.litres) : 0;

    if (numberOfFuelItems > 0) {
      expense = createAdditionalExpense("Fuel", numberOfFuelItems, newFlightExpense);

    if (expense)
        newFlightExpense.expenseDetails.push(expense);
    } else {
      newFlightExpense.expenseDetails = newFlightExpense.expenseDetails.filter(x => !(x.rateType === "Fuel" && x.expenseId === "00000000-0000-0000-0000-000000000000"));
      newFlightExpense.expenseDetails.filter(x => x.rateType === "Fuel").map(x => x.deleted = true);
      newFlightExpense.flightFuelUsage.litres = '0';
    }

    const numberOfDoubleCrewItems = newFlightExpense.flightFuelUsage.doubleCrew ? 1 : 0;
    if (numberOfDoubleCrewItems > 0) {
      const expense = createAdditionalExpense("Double Crew", numberOfDoubleCrewItems, newFlightExpense);
      if (expense)
        newFlightExpense.expenseDetails.push(expense);
    } else {
      newFlightExpense.expenseDetails = newFlightExpense.expenseDetails.filter(x => !(x.rateType === "Double Crew" && x.expenseId === "00000000-0000-0000-0000-000000000000"));
      newFlightExpense.expenseDetails.filter(x => x.rateType === "Double Crew").map(x => x.deleted = true);
    }

    newFlightExpense.flightFuelUsage.updateTimestamp = moment(new Date()).format("yyyy-MM-DD");

    if (newFlightExpense.flightFuelUsage.flightFuelUsageId === "00000000-0000-0000-0000-000000000000")
      newFlightExpense.flightFuelUsage.createTimestamp = moment(new Date()).format("yyyy-MM-DD");

    if (newFlightExpense.flightFuelUsage)
      setFormValues(newFlightExpense);

    //send on change to parent component
    onChange(newFlightExpense);
  }

  async function onPropertyChange(idx: number, value: Partial<FlightCrewEligibility>) {

    let newValue = new FlightCrewEligibility(formValues.flightCrewEligibility.filter(x => !x.deleted)[idx]);
    newValue = new FlightCrewEligibility({ ...newValue, ...value });
    let rows = [...formValues.flightCrewEligibility];
    let updatingRow = rows.filter(x => !x.deleted)[idx];
    rows[rows.indexOf(updatingRow)] = newValue;

    let newFlightExpense = new FlightExpenses({ ...formValues, flightCrewEligibility: rows });

    const additionalExpenses =await getAdditionalExpenses(newFlightExpense);
    newFlightExpense.expenseDetails = [...newFlightExpense.expenseDetails, ...additionalExpenses];

    newFlightExpense.flightFuelUsage.createUserId = auth?.user?.profile?.name ?? "wildfire";

    setFormValues(newFlightExpense);

    //send on change to parent component "FlightReportSummary"
    onChange(newFlightExpense);
  }

  function onPropertyChangeExpenseDetail(idx: number, value: Partial<ExpenseDetail>) {
    const deletedExpenses = formValues.expenseDetails.filter(x => x.deleted);
    let newValue = new ExpenseDetail(formValues.expenseDetails.filter(x => !x.deleted)[idx]);
    newValue = new ExpenseDetail({ ...newValue, ...value });
    let rows = [...formValues.expenseDetails].filter(x => !x.deleted);
    rows[idx] = newValue;
    let newFlightExpense = new FlightExpenses({ ...formValues, expenseDetails: [...rows, ...deletedExpenses] });
    newFlightExpense.flightFuelUsage.createUserId = auth?.user?.profile?.name ?? "wildfire";
    setFormValues(newFlightExpense);

    //send on change to parent component "FlightReportSummary"
    onChange(newFlightExpense);
  }

  const getAdditionalExpenses = async (updatedFlightExpense: FlightExpenses): Promise<ExpenseDetail[]> => {
    const additionalExpenses: ExpenseDetail[] = [];
    for (const rateTypeLabel of rateTypeLabels) {
      const numberOfItems = getNumberOfVendorProvidedItems(rateTypeLabel, updatedFlightExpense.flightCrewEligibility);
      if (numberOfItems > 0) {
        const expense = createAdditionalExpense(rateTypeLabel.label, numberOfItems, updatedFlightExpense);
        if (expense) {
          additionalExpenses.push(expense);
        }
      } else {
        updatedFlightExpense.expenseDetails = updatedFlightExpense.expenseDetails.filter(x => !(x.rateType === rateTypeLabel.label && x.expenseId === "00000000-0000-0000-0000-000000000000"));
        updatedFlightExpense.expenseDetails.filter(x => x.rateType === rateTypeLabel.label).forEach(item => {
          if (item.expenseId !== "00000000-0000-0000-0000-000000000000") {
            item.deleted = true;
          }
        });
        setFormValues(updatedFlightExpense);
      }
    }
    return additionalExpenses;
  };

  const removeAdditionalExpenses = (updatedFlightExpense: FlightExpenses, deletedFlightCrewEligibility: FlightCrewEligibility) => {
    return new Promise<void>((resolve) => {
      for (const rateTypeLabel of rateTypeLabels) {
        let rateTypeExpense = updatedFlightExpense.expenseDetails.find(x => x.rateType === rateTypeLabel.label);
        let numberOfdeletedExpense = getNumberOfVendorProvidedItemsOnDelete(rateTypeLabel, deletedFlightCrewEligibility)
        if (rateTypeExpense && !rateTypeExpense?.deleted) {
          rateTypeExpense.noOfUnits = rateTypeExpense?.noOfUnits - numberOfdeletedExpense;

          if (rateTypeExpense.noOfUnits === 0) {
            if (rateTypeExpense.expenseId === "00000000-0000-0000-0000-000000000000")
              updatedFlightExpense.expenseDetails = updatedFlightExpense.expenseDetails.filter(
                (expense) => expense !== rateTypeExpense);
            else
              rateTypeExpense.deleted = true;
          }
        }
      }

      // Resolve the promise to indicate that removeAdditionalExpenses is completed
      resolve();
    });
  };

  const getNumberOfVendorProvidedItems = (expenseType: any,
    flightCrewEligibility: any[]) => {
    const vendorProvidedItems = flightCrewEligibility.filter(
      (x) => x[expenseType.fieldName] === "Vendor Provided" && !x.deleted
    );

    return vendorProvidedItems?.length;
  }

  const getNumberOfVendorProvidedItemsOnDelete = (expenseType: any, flightCrewEligibility: any) => {
    if (flightCrewEligibility[expenseType.fieldName] === "Vendor Provided") {
      return 1;
    } else {
      return 0;
    }
  };

  async function getExpenseRate(): Promise<IPaginationResult<IRate>> {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objISearch: ISearch = {
      search: "",
      sortBy: "",
      sortOrder: "",
      pagination: objIPagination,
    };

    try {
      const response = await RateService.getSearch(objISearch);
      // Assuming the response.data is an array of Rate objects
      return response.data as IPaginationResult<IRate>;
    } catch (e) {
      // Handle errors, you may want to re-throw the error or handle it as needed
      setIsLoading(false);
      throw e;
    }
  }

  //Fire number
  async function getFireNumbers(idx:number = -1, searchValue:string = "")
  {
    try {
      var fireNumberResponse: any = await FlyingDetailCRUD.getFireNumbers(flightReport.flightReportDate, searchValue);
      if (fireNumberResponse.status)
      {
        const fireNumberResult = bindFireNumberDropdown(fireNumberResponse.data);

        if(idx !== -1 && formValues.expenseDetails[idx] && fireNumberResult)
        {
          onDrodownSearch(idx,{fireNumberList:fireNumberResult});
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }
  
  //Costing activity
  async function getCostingActivities(idx: number = -1, searchValue: string = "") {
    try {
      var costingActivityResponse: any = await FlyingDetailCRUD.getCostingActivities(searchValue);
      if (costingActivityResponse.status) {
        const costingActivityResult = bindCostingActivityDropdown(costingActivityResponse.data);

        if (idx !== -1 && formValues.expenseDetails[idx] && costingActivityResult) {
          onDrodownSearch(idx, { costingActivityList: costingActivityResult });
        }
      }
    }
    catch (Error) {
      console.log("Error", Error);
    }
  }

  //Internal order
async function getInternalOrder(idx:number = -1, searchValue:string = ""){
  try {
    var internalOrderResponse: any = await FlyingDetailCRUD.getInternalOrder(searchValue, auth.user?.access_token as string);
    if (internalOrderResponse.data)
    {
      const internalOrderResult = bindInternalOrderDropdown(internalOrderResponse.data);
      
      if(idx !== -1 && formValues.expenseDetails[idx] && internalOrderResult)
      {
        onDrodownSearch(idx,{internalOrderList:internalOrderResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}

//Cost center
async function getCostCenter(idx:number = -1, searchValue:string = ""){
  try {
    var costCenterResponse: any = await FlyingDetailCRUD.getCostCenter(searchValue, auth.user?.access_token as string);
    if (costCenterResponse.data)
    {
      const costCenterResult = bindCostCenterDropdown(costCenterResponse.data);
      
      if(idx !== -1 && formValues.expenseDetails[idx] && costCenterResult)
      {
        onDrodownSearch(idx,{costCenterList:costCenterResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}

//Fund
async function getFund(idx:number = -1, searchValue:string = ""){
  try {
    var fundResponse: any = await FlyingDetailCRUD.getFund(searchValue, auth.user?.access_token as string);
    if (fundResponse.data)
    {
      const fundResult = bindFundDropdown(fundResponse.data);
      
      if(idx !== -1 && formValues.expenseDetails[idx] && fundResult)
      {
        onDrodownSearch(idx,{fundList:fundResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}

//Account
async function getAccount(idx:number = -1, searchValue:string = ""){
  try {
    var accountResponse: any = await FlyingDetailCRUD.getAccount(searchValue, auth.user?.access_token as string);
    if (accountResponse.data)
    {
      const accountResult = bindAccountDropdown(accountResponse.data);
      
      if(idx !== -1 && formValues.expenseDetails[idx] && accountResult)
      {
        onDrodownSearch(idx,{accountList:accountResult});
      }
    }
  }
  catch (Error) {
    console.log("Error", Error);
  }
}

  async function handleAddRow() {
    let newValue = new FlightCrewEligibility();
    newValue.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
    newValue.createUserId = auth?.user?.profile?.name ?? "wildfire";
    newValue.flightReportId = flightReport.flightReportId;

    formValues.flightCrewEligibility = [...formValues.flightCrewEligibility, newValue];

    if (formValues.flightFuelUsage.flightFuelUsageId === "00000000-0000-0000-0000-000000000000")
      formValues.flightFuelUsage.createTimestamp = moment(new Date()).format("yyyy-MM-DD");

    // Update the state and trigger a re-render
    let newFlightExpense = new FlightExpenses({ ...formValues});

    setFormValues(newFlightExpense);

    //temporary fix: remove this once found out why second new row is not being added on add new
    //onPropertyChange(formValues.flightCrewEligibility.findIndex((x: FlightCrewEligibility) => x.flightCrewEligibilityId === "00000000-0000-0000-0000-000000000000"), { flightCrewEligibilityId: "00000000-0000-0000-0000-000000000000" });
  }

  function onDrodownSearch(idx: number, value: Partial<ExpenseDetail>) {
    let newValue = new ExpenseDetail(formValues.expenseDetails[idx]);

    newValue = new ExpenseDetail({ ...newValue, ...value });
    let rows = [...formValues.expenseDetails];
    rows[idx] = newValue;

    let newFlightExpense = new FlightExpenses({ ...formValues, expenseDetails: [...rows] });

    setFormValues(newFlightExpense);
  }

  function handleDelete(id: string, idx: number) {
    // Create a copy of formValues
    const updatedFormValues = { ...formValues };

    var tobeDeletedIndex;
    // Find the item to be deleted
    //const tobeDeletedIndex='';
    if (id === "00000000-0000-0000-0000-000000000000")
      tobeDeletedIndex = idx;
    else
      tobeDeletedIndex = updatedFormValues.flightCrewEligibility.findIndex(x => x.flightCrewEligibilityId === id && !x.deleted);


    if (tobeDeletedIndex !== -1) {
      // Create a copy of the item to be deleted
      const tobeDeleted = { ...updatedFormValues.flightCrewEligibility[tobeDeletedIndex] };

      // Update the 'deleted' property
      tobeDeleted.deleted = true;

      // Replace the item in the updatedFormValues array
      updatedFormValues.flightCrewEligibility[tobeDeletedIndex] = tobeDeleted;

      // Call removeAdditionalExpenses and wait for it to complete
      removeAdditionalExpenses(updatedFormValues, tobeDeleted)
        .then(() => {
          // remove it if its not from db
          if (id === "00000000-0000-0000-0000-000000000000") {
            updatedFormValues.flightCrewEligibility.splice(idx, 1);
          }

          // Update the state and trigger a re-render
          setFormValues(updatedFormValues);

          // Call onChange with the updatedFormValues
          onChange(updatedFormValues);
        });
    }
  }


  return (
    <>
      <PageLoader visible={loading} />
      <div className="row section-margin-top-20">
        <div className="width100">
          <div className="column width50">
            <div className="row accordion fuel-edit">
              <div>
                <h3 className="insideHeader expense-section-header">Fuel</h3>
              </div>
              <div className="content">
                <div className='width50 col-md-6'>
                  <GoAFormItem label="Provider">
                    <Select
                      name="providers"
                      options={providerOptions}
                      className="width50"
                      value={providerOptions.find(t => t.value === formValues.flightFuelUsage?.provider) || null}
                      onChange={(value) => {
                        onPropertyChangeFuelUsage({ provider: value?.value });
                      }}
                    />
                  </GoAFormItem>
                </div>
                <div className='width50 col-md-6'>
                  <GoAFormItem label="Litres">
                    <GoAInputText
                      name="litre"
                      disabled={flightExpenses?.flightFuelUsage?.provider !== "Vendor Provided"}
                      error={flightExpenses?.flightFuelUsage?.provider === "Vendor Provided" && formValues?.flightFuelUsage?.litres === '0'}
                      value={formValues?.flightFuelUsage?.litres}
                      onChange={(name, value) => {
                        if(value)
                          onPropertyChangeFuelUsage({ litres: value });
                      }}
                    />
                  </GoAFormItem>
                </div>
              </div>

            </div>
          </div>
          <div className="right width50 paddingLeft20px">

            <div className="row accordion flight-crew-edit">
              <div>
                <div>
                  <h3 className="insideHeader expense-section-header">Double Crew</h3>
                </div>
                <div className="content">
                  <div className='width100 col-md-6'>
                    <GoAFormItem label="">
                      <GoARadioGroup name="doubleCrew" value={flightExpenses?.flightFuelUsage?.doubleCrew ? 'true' : 'false'}
                        onChange={(_name, value) => {
                          onPropertyChangeFuelUsage({ doubleCrew: value === 'true' ? true : false });
                        }}
                      >
                        <GoARadioItem value="true" label="Yes" name={''} />
                        <GoARadioItem value="false" label="No" name={''} />
                      </GoARadioGroup>
                    </GoAFormItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row accordion flight-crew-eligibility section-margin-top-20">
        <div>
          <div>
            <h3 className="insideHeader expense-section-header">
              Flight Crew Eligibility
            </h3>
          </div>
          <div className="content">
            <div className="white-background">
              <GoATable width="100%">
                <thead>
                  <tr style={{ position: "sticky", top: 0 }}>
                    <th className="width15">Name</th>
                    <th className="width15">Role</th>
                    <th className="width15">Breakfast</th>
                    <th className="width15">Lunch</th>
                    <th className="width15">Dinner</th>
                    <th className="width10">Lodging</th>
                    <th className="width10">Vehicle</th>
                  </tr>
                </thead>

                <tbody>
                  {formValues.flightCrewEligibility.filter(x => !x.deleted)?.map(
                    (record: FlightCrewEligibility, idx: number) => (
                      <tr key={idx}>
                        <td>
                          <GoAInputText
                            name="name"
                            value={record.name}
                            onChange={(name, value) => {
                              onPropertyChange(idx, { name: value });
                            }}
                          />
                        </td>
                        <td>
                          <Select
                            name="roles"
                            options={rolesOptions}
                            className="width100"
                            value={rolesOptions.find(t => t.value === record.role) || rolesOptions.find(t => t.value === 'Pilot')}
                            onChange={(value) => {
                              onPropertyChange(idx, { role: value?.value });
                            }}
                          />

                        </td>
                        <td>
                          <Select
                            name="breakfast"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.breakfast) || providerOptions.find(t => t.value === 'GOA Provided')}
                            onChange={(value) => {
                              onPropertyChange(idx, { breakfast: value?.value });
                            }}
                          />

                        </td>
                        <td>
                          <Select
                            name="lunch"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.lunch) || providerOptions.find(t => t.value === 'GOA Provided')}
                            onChange={(value) => {
                              onPropertyChange(idx, { lunch: value?.value });
                            }}
                          />
                        </td>
                        <td>
                          <Select
                            name="dinner"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.dinner) || providerOptions.find(t => t.value === 'GOA Provided')}
                            onChange={(value) => {
                              onPropertyChange(idx, { dinner: value?.value });
                            }}
                          /></td>
                        <td>
                          <Select
                            name="lodging"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.lodging) || providerOptions.find(t => t.value === 'GOA Provided')}
                            onChange={(value) => {
                              onPropertyChange(idx, { lodging: value?.value });
                            }}
                          />
                        </td>
                        <td>
                          <Select
                            name="vehicle"
                            options={providerOptions}
                            className="width100"
                            value={providerOptions.find(t => t.value === record.vehicle) || providerOptions.find(t => t.value === 'GOA Provided')}
                            onChange={(value) => {
                              onPropertyChange(idx, { vehicle: value?.value });
                            }}
                          /></td>
                        <td>
                          <GoAButton
                            //disabled={formValues.expenseDetails.filter(x => !x.deleted && (x.rateType === 'Fuel' || x.rateType === 'Double Crew')).length > 0 && formValues.flightCrewEligibility.filter(x => !x.deleted).length === 1}
                            type="tertiary" leadingIcon="trash"
                            onClick={() => handleDelete(record.flightCrewEligibilityId, idx)}></GoAButton>
                        </td>
                      </tr>
                    )
                  )
                  }
                  <tr>
                    <td>
                      <GoAButton type="secondary" onClick={() => handleAddRow()}>
                        Add New +
                      </GoAButton>
                    </td>
                  </tr>
                </tbody>

              </GoATable>

            </div>

          </div>
        </div>
      </div>

      <div>
        <GoATable width="100%">
          <thead>
            <tr style={{ position: "sticky", top: 0 }}>
              <th className="width10">Fire number</th>
              <th className="width13">Activity</th>
              <th className="width8">Rate type</th>
              <th className="width7">No.of units</th>
              <th className="width5">Unit</th>
              <th className="width8">Rate per unit</th>
              <th>Cost</th>
              <th className="width12">Internal order</th>
              <th className="width12">Cost center</th>
              <th className="width12">Fund</th>
              <th className="width12">Account</th>
            </tr>
          </thead>

          <tbody style={{ position: "sticky", top: 0 }}>
            {formValues?.expenseDetails?.filter(x => !x.deleted).map(
              (_record: any, idx: number) => (
                <tr key={idx}>
                  <td>
                    <Select
                      name={"selFireNmbers" + idx}
                      options={_record?.fireNumberList}
                      placeholder="--Select--"
                      className="width100"
                      value={_record?.fireNumberList.find((t: { value: any; }) => t.value === _record?.fireNumberId) ?? ''}
                      onChange={async (value: any) => {
                        if(value.value)
                        {
                          onPropertyChangeExpenseDetail(idx, { costingActivityId: '', costingActivityCode: "", fireNumberId: value.value, fireNumber: value.fireNumber, fireYear:value.fireYear });
                        }
                        else{
                          onPropertyChangeExpenseDetail(idx, { costingActivityId: '', costingActivityCode: "", fireNumberId: undefined, fireNumber: "", fireYear:0 });
                        }
                      }}
                      onInputChange={(value:any, actionMeta:any)=>{
                        if(actionMeta.action === ActionEvents.inputChange)
                          getFireNumbers(idx,value);
                      }}
                      isSearchable={true}
                    />
                  </td>
                  <td>
                    <Select
                      name={"selCostingActivity" + idx}
                      //options={_record.fireNumber ? _record?.costingActivityList.filter((x: any) => x.label.startsWith("FS")) : _record?.costingActivityList.filter((x: any) => !x.label.startsWith("FS"))}
                      options={ _record.fireNumberId && isCostingActivitySearch ? _record?.costingActivityList :
                        _record.fireNumberId && !isCostingActivitySearch ? _record?.costingActivityList.filter((x: any) => x.label.startsWith("FS")) :
                        _record?.costingActivityList
                      }
                      placeholder="--Select--"
                      className="width100"
                      //value={_record?.costingActivityId !== undefined ? _record?.costingActivityList.find((x: any ) => x.value === _record?.costingActivityId) : ''}
                      //value={_record?.costingActivityList.find((x: any ) => x.value === _record?.costingActivityId)}
                      //value={_record?.costingActivityList.find((x: any ) => x.value === _record?.costingActivityId) ?? ''}
                      value={_record?.costingActivityId !== "00000000-0000-0000-0000-000000000000" ? _record?.costingActivityList.find((x: any ) => x.value === _record?.costingActivityId) : ''}
                      onChange={async (value: any) => {
                        if(value.value)
                        {
                          onPropertyChangeExpenseDetail(idx, { costingActivityId: value.value, costingActivityCode: value.code });
                        }
                        setIsCostingActivitySearch(false);
                      }}
                      onInputChange={(value:any, actionMeta:any)=>{
                        if(actionMeta.action === ActionEvents.inputChange)
                        {
                          setIsCostingActivitySearch(true);
                          getCostingActivities(idx, value);
                        }
                      }}
                      isSearchable={true}
                    />
                  </td>
                  <td>
                    {rateTypeOptions.find((t: { value: any; }) => t.value === _record?.rateTypeId)?.label}
                  </td>
                  <td>{_record?.noOfUnits}
                  </td>
                  <td>
                    {rateUnitOptions.find((t:any) => t.value === _record?.rateUnitId)?.label}
                  </td>
                  <td>
                    <td>
                      <GoAInputText
                        name="ratePerUnit"
                        width="100%"
                        value={_record?.ratePerUnit}
                        onChange={(name, value) => {
                          onPropertyChangeExpenseDetail(idx, { ratePerUnit: Number.parseFloat(value) });
                        }}
                      />
                    </td>
                  </td>
                  <td>
                    {"$" + _record?.cost.toFixed(2)}
                  </td>
                  <td>
                    <Select
                      name={"selInternalOrders"+idx}
                      //options={ internalOrderOptions }
                      options={_record?.internalOrderList }
                      placeholder="--Select--"
                      className="width100"
                      //value={internalOrderOptions?.find((t: { value: any; }) => t.value === _record?.internalOrder)}
                      value={_record?.internalOrderList?.find((t: { value: any; }) => t.value === _record?.internalOrder)}
                      onChange={async (value:any) =>{
                        if(value.value)
                        {
                          onPropertyChangeExpenseDetail(idx,{internalOrder:value.value});
                        }
                      }}
                      onInputChange={(value:any, actionMeta:any)=>{
                        if(actionMeta.action === ActionEvents.inputChange)
                          getInternalOrder(idx,value);
                      }}
                      isSearchable={true}
                    />
                  </td>
                  <td>
                    <Select
                      name={"selCostCenter"+idx}
                      //options={ costCenterOptions }
                      options={_record?.costCenterList }
                      placeholder="--Select--"
                      className="width100"
                      value={_record?.costCenterList?.find((t: any) => t.value === _record?.costCenter)}
                      onChange={async (value:any) =>{
                        if(value.value)
                        {
                          onPropertyChangeExpenseDetail(idx,{costCenter:value.value});
                        }
                      }}
                      onInputChange={(value:any, actionMeta:any)=>{
                        if(actionMeta.action === ActionEvents.inputChange)
                          getCostCenter(idx,value);
                      }}
                      isSearchable={true}
                    />
                  </td>
                  <td>
                    <Select
                      name={"selFund"+idx}
                      //options={ fundOptions }
                      options={_record?.fundList }
                      placeholder="--Select--"
                      className="width100"
                      value={_record?.fundList?.find((t: any) => t.value === _record?.fund)}
                      onChange={async (value:any) =>{
                        if(value.value)
                        {
                          onPropertyChangeExpenseDetail(idx,{fund:value.value});
                        }
                      }}
                      onInputChange={(value:any, actionMeta:any)=>{
                        if(actionMeta.action === ActionEvents.inputChange)
                          getFund(idx,value);
                      }}
                      isSearchable={true}
                    />
                  </td>
                  <td>
                    <Select
                      name={"selAccount"+idx}
                      //options={ accountOptions }
                      options={_record?.accountList }
                      placeholder="--Select--"
                      className="width100"
                      value={_record?.accountList?.find((t: any) => t.value === _record?.account)}
                      onChange={async (value:any) =>{
                        if(value.value)
                        {
                          onPropertyChangeExpenseDetail(idx,{account:value.value});
                        }
                      }}
                      onInputChange={(value:any, actionMeta:any)=>{
                        if(actionMeta.action === ActionEvents.inputChange)
                          getAccount(idx,value);
                      }}
                      isSearchable={true}
                    />
                  </td>
                </tr>
              )
            )
            }
            
          </tbody>
        </GoATable>
      </div>
    </>
  );
};

export default EditExpenseDetails;
