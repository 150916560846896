import {
  GoAFormItem,
  GoAInputDate,
  GoAInputText,
  GoARadioGroup,
  GoARadioItem,
} from "@abgov/react-components";
import moment from "moment";
import * as React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FlightReport } from "../../model/IFlightReport";
import {
  bindMutltiSelectDropdown,
  getCorporateRegion,
} from "../../services/domainServices/CorporateRegionDomainService";
import {
  bindSelectContractRegistration,
  getContractRegistration,
} from "../../services/HiringContractService";
import { hiringContractsState } from "../../state/DomainService.state";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { IFlyingHours } from "../../model/IFlyingHours";
import Select from "react-select";
import { validate } from "../../utils/commonMethods";
import { FlightReportCRUD } from "../../operations/FlightReportCRUD";
import { IArrayOption } from "../../model/IArrayOption";

interface IEditFlightReportSummaryProps {
  ruleCode: string;
  flightReportList: IPaginationResult<IFlyingHours> | undefined;
  flightReport: FlightReport;
  corporateRegionPaginationResult: any;
  visible?: boolean;
  onChildDataValidityChange: any;
  onChange: (value: FlightReport) => void;
  onChangeNoFlyDay:() => void;
}

const EditFlightReportSummary: React.FunctionComponent<
  IEditFlightReportSummaryProps
> = ({
  ruleCode,
  flightReportList,
  flightReport,
  corporateRegionPaginationResult,
  visible,
  onChange,
  onChildDataValidityChange,
  onChangeNoFlyDay,
  ...props
}) => {
  //const $: JQueryStatic = jquery;

  const [formValues, setFormValues] = React.useState(
    new FlightReport(flightReport)
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxDate, setMaxDate] = React.useState(new Date());
  //const [maxDate, setMaxDate] = React.useState(getDateWithMonthOffset(1));

  const [forestAreaOptions, setForestAreaOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [flightReportDateError, setFlightReportDateError] =
    React.useState(false);

  //Contract registration
  const setHiringContracts = useSetRecoilState(hiringContractsState);
  const hiringContracts = useRecoilValue(hiringContractsState);

  const [contractRegistrationOptions, setContractRegistrationOptions] =
    React.useState<IArrayOption[]>(
      []
    );
  //Flying registration
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [flyingRegistrationOptions, setFlyingRegistrationOptions] =
    React.useState<IArrayOption[]>(
      []
    );

  React.useEffect(() => {
    if (corporateRegionPaginationResult !== null) {
      const forestAreaResult = bindMutltiSelectDropdown(
        corporateRegionPaginationResult
      );
      setForestAreaOptions(forestAreaResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setFormValues(flightReport);

    //bind Contract Registration & Flying Registration
    bindAircraftRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightReport]);

  function bindAircraftRegistration() {
    if (hiringContracts !== undefined) {
      const contractRegistrationResult = bindSelectContractRegistration(hiringContracts);
      const flyingRegistrationResult = bindSelectContractRegistration(hiringContracts);
      
      if (contractRegistrationResult) {
        setContractRegistrationOptions(contractRegistrationResult);
        setFlyingRegistrationOptions(flyingRegistrationResult);
      }
    }
  }

  //#endregion "Hiring Contract"

  //on property values change
  function onPropertyChange(value: Partial<FlightReport>) {
    let newValue = new FlightReport();

    newValue = new FlightReport({ ...flightReport, ...value });

    setFormValues(newValue);
    //send on change to parent component 'Flight report summary'
    onChange(newValue);
  }

  function handleKeyUp(elementName: string) {
    // Notify the parent about the validity
    onChildDataValidityChange(validate(ruleCode, elementName, "keyup"));
  }

  async function handleDateChange(elementName: string) {
    onChildDataValidityChange(validate(ruleCode, elementName, "onChange"));

    const startDateElement = document.querySelector(
      '[name="dtFlightDate"]'
    ) as HTMLInputElement;
    const selectedDate = startDateElement.value;

    if (selectedDate) {
      let dtResult = moment(selectedDate).format("yyyy-MM-DD");
      let splitDate = dtResult.split("-");

      if (parseInt(splitDate[0]) < 1950) setFlightReportDateError(true);
      else if (parseInt(splitDate[2]) > 31) setFlightReportDateError(true);
      else {
        //Get contract registartion & flying registration based on flight report date
        var hiringContracts = await FlightReportCRUD.getHiringContract(
          dtResult
        );
        setHiringContracts(hiringContracts);

        setFlightReportDateError(false);
        onPropertyChange({ flightReportDate: dtResult });
      }
    } else {
      onPropertyChange({ flightReportDate: "" });
    }
  }

  return (
    <>
      <div>
        <div className="form-row">
          <div className="col-50">
            <div>
              <label className="label regular">
                Flight Date<span className="mandatory">*</span>
                <em>(required)</em>
              </label>
            </div>
            <GoAInputDate
              name="dtFlightDate"
              placeholder="YYYY-MM-DD"
              value={
                formValues?.flightReportDate != null ||
                formValues?.flightReportDate !== ""
                  ? formValues?.flightReportDate
                  : new Date()
              }
              min={new Date(1950, 1, 1)}
              max={maxDate}
              width="100%"
              disabled={formValues?.flightReportId && formValues?.flightReportDate ? true : false}
              onChange={(name, value) => {
                handleDateChange(name);
              }}
            />
          </div>
          <div className="col-50">
            <GoAFormItem label="AO-02 Number">
              <GoAInputText
                name="AO02Number"
                value={formValues?.ao02Number as string}
                width="100%"
                maxLength={10}
                onChange={(name, value) => {
                  onPropertyChange({ ao02Number: value.slice(0, 10) });
                }}
              />
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <div>
              <label className="label">
                Contract Registration<span className="mandatory">*</span>
                <em>(required)</em>
              </label>
            </div>
            <Select
              name="selContractRegistration"
              options={contractRegistrationOptions}
              placeholder="--Select--"
              className="width100"
              isDisabled={!formValues.flightReportDate || formValues?.flightReportId}
              value={contractRegistrationOptions.find(
                (t) => t.value === formValues.contractRegistration?.id
              )}
              onChange={async (value: any) => {
                if (value) {
                  var contractRegistration = getContractRegistration(
                    hiringContracts,
                    value.value
                  );
                  
                  var objVendor = {name: contractRegistration?.name1, financeVendorId:contractRegistration?.financeVendorId}

                  onPropertyChange({
                    contractRegistration: contractRegistration ?? undefined,
                    contractRegistrationId: value.value,
                    hiringStartDate: contractRegistration?.effectiveDate,
                    hiringEndDate: contractRegistration?.terminationDate,
                    pointOfHire: contractRegistration?.pointOfHire,
                    remark:contractRegistration?.remark,

                    financeVendorId: contractRegistration?.financeVendorId,
                    vendorName: contractRegistration?.name1,
                    
                    vendor: objVendor,
                    //vendorId: vendor?.data[0].vendorId,
                    flyingRegistration: contractRegistration ?? undefined,
                    flyingRegistrationId: value.value,
                  });
                  onChildDataValidityChange(
                    validate(
                      ruleCode,
                      "selContractRegistration",
                      "onChange",
                      value
                    )
                  );
                }
              }}
              onInputChange={(value: any) => {
                if (value)
                  contractRegistrationOptions.find((t) => t.value === value);
              }}
              isSearchable={true}
            />
          </div>
          <div className="col-50">
            <div>
              <label className="label">
                Flying Registration<span className="mandatory">*</span>
                <em>(required)</em>
              </label>
            </div>
            <Select
              name="selFlyingRegistration"
              options={flyingRegistrationOptions}
              placeholder="--Select--"
              className="width100"
              isDisabled={!formValues.flightReportDate}
              value={flyingRegistrationOptions.find(
                (t) => t.value === formValues.flyingRegistration?.id
              )}
              onChange={(value: any) => {
                onPropertyChange({
                  flyingRegistration:
                    getContractRegistration(hiringContracts, value.value) ??
                    undefined,
                  flyingRegistrationId: value.value,
                });
                onChildDataValidityChange(
                  validate(ruleCode, "selFlyingRegistration", "onChange", value)
                );
              }}
              onInputChange={(value: any) => {
                if (value)
                  flyingRegistrationOptions.find((t) => t.value === value);
              }}
              isSearchable={true}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Meter Unserviceable?">
              <div className="left">
                <GoARadioGroup
                  name="rbtnMeterUnserviceable"
                  value={formValues?.meterUS === true ? "Yes" : "No"}
                  orientation="horizontal"
                  onChange={(_name, value) => {
                    let result = value === "Yes" ? true : false;
                    onPropertyChange({ meterUS: result });
                  }}
                >
                  <GoARadioItem value="Yes" name={"Yes"} />
                  <GoARadioItem value="No" name={"No"} />
                </GoARadioGroup>
              </div>
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="No Fly Day?">
              <div className="left">
                <GoARadioGroup
                  name="rbtnNoFlyDay"
                  value={formValues?.noFlyDay === true ? "Yes" : "No"}
                  orientation="horizontal"
                  onChange={(_name, value) => {
                    let result = value === "Yes" ? true : false;
                    onPropertyChange({ noFlyDay: result });
                    if(result)
                    {
                      onChangeNoFlyDay();
                    }
                  }}
                >
                  <GoARadioItem value="Yes" name={"Yes"} />
                  <GoARadioItem value="No" name={"No"} />
                </GoARadioGroup>
              </div>
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Meter Start">
              <GoAInputText
                name="txtMeterStart"
                value={
                  formValues?.meterStart != null ? formValues?.meterStart : ""
                }
                width="100%"
                maxLength={10}
                disabled={formValues?.meterUS || formValues?.noFlyDay}
                onChange={(name, value) => {
                  if (value) {
                    onPropertyChange({ meterStart: value });
                    handleKeyUp(name);
                  } else {
                    onPropertyChange({ meterStart: null });
                  }
                }}
              />
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="Meter Finish">
              <GoAInputText
                name="txtMeterFinish"
                value={
                  formValues?.meterFinish != null ? formValues?.meterFinish : ""
                }
                width="100%"
                maxLength={10}
                disabled={formValues?.meterUS || formValues?.noFlyDay}
                onChange={(name, value) => {
                  if (value) {
                    onPropertyChange({ meterFinish: value });
                    handleKeyUp(name);
                  } else {
                    onPropertyChange({ meterFinish: null });
                  }
                }}
              />
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Meter Difference">
              <GoAInputText
                name="txtMeterDifference"
                value={
                  formValues?.meterStart && formValues?.meterFinish
                    ? (
                        formValues?.meterFinish - formValues?.meterStart
                      ).toFixed(1)
                    : ""
                }
                width="100%"
                disabled={true}
                onChange={(name, value) => {}}
              />
            </GoAFormItem>
          </div>
        </div>


        <div className="form-row">
          <div className="col-50">
            <div>
              <label className="label">
                Forest Area<span className="mandatory">*</span>
                <em>(required)</em>
              </label>
            </div>
            <Select
              name="selForestArea"
              options={forestAreaOptions}
              placeholder="--Select--"
              className="width100"
              value={forestAreaOptions.find(
                (t) => t.value === formValues.corporateRegion?.corporateRegionId
              )}
              onChange={(value: any) => {
                if (value) {
                  onPropertyChange({
                    corporateRegion:
                      getCorporateRegion(
                        corporateRegionPaginationResult,
                        value.value
                      ) ?? undefined,
                    corporateRegionId: value.value,
                  });
                  onChildDataValidityChange(
                    validate(ruleCode, "selForestArea", "onChange", value)
                  );
                }
              }}
              isSearchable={true}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Departure Location">
              <GoAInputText
                name="txtDepartureLocation"
                value={formValues?.pointOfDeparture != null ? formValues?.pointOfDeparture : ""}
                width="100%"
                maxLength={20}
                disabled={formValues?.noFlyDay}
                onChange={(name, value) => {
                  onPropertyChange({ pointOfDeparture: value.slice(0, 20) });
                }}
              />
            </GoAFormItem>
          </div>
          <div className="col-50">
            <GoAFormItem label="Down For Night Location">
              <GoAInputText
                name="downForNightLocation"
                value={formValues?.downForNightLocation ?? ""}
                width="100%"
                maxLength={20}
                onChange={(name, value) => {
                  onPropertyChange({
                    downForNightLocation: value.slice(0, 20),
                  });
                }}
              />
            </GoAFormItem>
          </div>
        </div>

        <div className="form-row">
          <div className="col-50">
            <GoAFormItem label="Unserviceable Report?">
              <div className="left">
                <GoARadioGroup
                  name="rbtnUnserviceableReport"
                  value={formValues?.unserviceableReport === true ? "Yes" : "No"}
                  orientation="horizontal"
                  onChange={(_name, value) => {
                    let result = value === "Yes" ? true : false;
                    onPropertyChange({ unserviceableReport: result });
                  }}
                >
                  <GoARadioItem value="Yes" name={"Yes"} />
                  <GoARadioItem value="No" name={"No"} />
                </GoARadioGroup>
              </div>
            </GoAFormItem>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFlightReportSummary;
