import { httpDomainServiceApim } from "../../utils/domainServiceAPIMConfig";
import { IRateUnit } from "../../model/domainServices/IRateUnit";
import { IArrayOption } from "../../model/IArrayOption";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch, strToken:string = "") => {
    const params = JSON.stringify({
      serviceName:objISearch.serviceName,
      search: objISearch.search,
      sortBy: objISearch.sortBy,
      sortOrder: objISearch.sortOrder,

      filterBy: {
        columnName: objISearch.filterBy?.columnName,
        columnValue: objISearch.filterBy?.columnValue
      },
      paginationInfo: {
        perPage: objISearch.pagination.perPage,
        page: objISearch.pagination.page,
      },
    });
  
    const data = await httpDomainServiceApim.post<IPaginationResult<IRateUnit>>(
      "/rate_units/get",
      params,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
    
    return data;
  };

  const getById = async (id: string, strToken:string = "") => {
    const data = await httpDomainServiceApim.post<IRateUnit>(
      `/rate_units/get/${id}`,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
  
    return data;
  };

  const getByOracleId = async (oracleId: number, strToken:string = "") => {
    const data = await httpDomainServiceApim.post<IRateUnit>(
      `/rate_units/get/${oracleId}`,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
  
    return data;
  };
  
  export const RateUnitService = {
    getSearch,
    getById,
    getByOracleId,
  };

  export const getRateUnit = (rateUnitStateResult:IPaginationResult<IRateUnit>, rateUnitId:string) => {
    if(rateUnitStateResult != null)
    {
      const result = rateUnitStateResult.data.find(value => value.rateUnitId === rateUnitId);
      return result;
    }
    else{
      return undefined;
    }
  }
  
  export const bindRateUnitDropdown = (rateUnitPaginationResult:IPaginationResult<IRateUnit>): IArrayOption[] => {
    const options = [];
    
    if(rateUnitPaginationResult != null)
    {
      for (let i = 0; i < rateUnitPaginationResult.data.length; i++) {
        let obj: IArrayOption = {label: rateUnitPaginationResult.data[i].type, value: rateUnitPaginationResult.data[i].rateUnitId};
        options.push(obj);
      }
    }

    return options;
  }