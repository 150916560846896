
import moment from "moment";
import { ICorporateRegion } from "./ICorporateRegion";
import { IHiringContract } from "./HiringContract/IHiringContract";
import { IVendor } from "./IVendor";
import { getDateFormat } from "../utils/dateHandler";

export interface IFlightReport{
    flightReportId:any,
    ao02Number:string,
    flightReportDate:string,

    meterFinish:any,
    meterStart:any,

    pointOfDeparture?:any,
    downForNightLocation?:string,
    noFlyDay:boolean,

    remark?:string,

    corporateRegionId:string,
    corporateRegion?:ICorporateRegion | undefined

    // vendorId?:any,
    vendor?:IVendor | undefined,

    financeVendorId?:string,
    vendorName?:string,

    contractRegistrationId?:any,
    contractRegistration?:IHiringContract | undefined,
    flyingRegistrationId?:any,
    flyingRegistration?:IHiringContract | undefined,

    status?:string,
    isFlagged:boolean,
    meterUS:boolean,
    unserviceableReport:boolean,

    hiringStartDate:any,
    hiringEndDate:any,
    pointOfHire:any,

    createUserId?:string,
    createTimestamp?:string,
    updateTimestamp?:string,
    updateUserId?: string,   
} 

export class FlightReport implements IFlightReport, IErrorState<Partial<IFlightReport>>{
    readonly flightReportId: any;
    readonly ao02Number: string;
    readonly flightReportDate: string;

    meterFinish: any;
    meterStart: any;

    pointOfDeparture?: any;
    readonly downForNightLocation?: string;
    readonly noFlyDay: boolean ;

    readonly remark?:string;

    readonly corporateRegionId: string;
    corporateRegion?: ICorporateRegion;
    
    // readonly vendorId: any ;
    vendor?: IVendor;

    readonly financeVendorId:string;
    readonly vendorName:string;

    readonly contractRegistrationId?: any ;
    readonly contractRegistration?:IHiringContract;
    readonly flyingRegistrationId?:any;
    readonly flyingRegistration?:IHiringContract;

    readonly status: string;
    isFlagged:boolean = false;
    readonly meterUS:boolean = false;
    readonly unserviceableReport:boolean = false;

    readonly hiringStartDate:any;
    readonly hiringEndDate:any;
    readonly pointOfHire:any;

     createUserId?: string;
     createTimestamp?: string;
     updateTimestamp?: string;
     updateUserId?: string;

    constructor(from?: Partial<IFlightReport>) {
        this.flightReportId = from?.flightReportId ?? "";
        this.ao02Number = from?.ao02Number ?? "";
        this.flightReportDate = from?.flightReportDate ?? getDateFormat(from?.flightReportDate);

        this.meterFinish= from?.meterFinish ?? null;
        this.meterStart= from?.meterStart ?? null;
        this.pointOfDeparture= from?.pointOfDeparture ?? null;
        this.downForNightLocation= from?.downForNightLocation ?? "";
        this.noFlyDay= from?.noFlyDay ?? false;

        this.remark = from?.remark ?? "";
        
        this.corporateRegionId= from?.corporateRegionId ?? "";
        this.corporateRegion= from?.corporateRegion ?? undefined;
        // this.vendorId= from?.vendorId ?? null;
        this.vendor= from?.vendor ?? undefined;

        this.financeVendorId = from?.financeVendorId ?? "";
        this.vendorName = from?.vendorName ?? "";

        this.contractRegistrationId= from?.contractRegistrationId?? null;
        this.contractRegistration = from?.contractRegistration ?? undefined;
        this.flyingRegistrationId = from?.flyingRegistrationId ?? null;
        this.flyingRegistration= from?.flyingRegistration ?? undefined;
    
        this.status= from?.status ?? "";
        this.isFlagged = from?.isFlagged ?? false;
        this.meterUS= from?.meterUS ?? false;
        this.unserviceableReport= from?.unserviceableReport ?? false;
    
        this.hiringStartDate= from?.hiringStartDate?? null;
        this.hiringEndDate= from?.hiringEndDate?? null;
        this.pointOfHire= from?.pointOfHire?? null;

        this.createUserId= from?.createUserId ?? "";
        this.createTimestamp= getDateFormat(from?.createTimestamp);
        this.updateTimestamp= getDateFormat(from?.updateTimestamp);
        this.updateUserId= from?.updateUserId ?? "";
        
      }

      get errorState(){
        return {flightReportDate:!this.flightReportDate ?  true : false }
      }

      timeFormat(value?: string): string {
        if (!value) return "";

        return moment(value).isValid() ? moment(value).format("HH:mm") : value;
       
        //     Intl.DateTimeFormat
        //     //var result = //format the value
        // return value;
      }

      getTimeFormat (value:string):string {
        if(value)
        {
          if(moment(value).isValid())
          {
            var time = moment(value).format("HH:mm");
            return time !== "00:00" ? time : "";
          }
          else{
            return value;
          }
        }
        else{
          return "";
        }
    }

      setStatus(value?: string):string{
        if(this.flightReportId === 0)
         return "Created"
        else
         return ""
      }

      // setCreateUserId(value?:string):string{
      //   if()
      // }

      // setCorporateRegion(corporateRegionId:string)
      // {
      //   return getCorporateRegion(corporateRegionPaginationResult,corporateRegionId);
        
      // }
    
}

export type ErrorState<T> = {
	[property in keyof T]: boolean;
};

export interface IErrorState<T> {
	errorState?: ErrorState<T>;
}





