import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { IFlightReportNote } from "../../model/IFlightReportNote";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";
 
const getNotes =  async (objISearch: ISearch,flightReportId:number, strToken:string = "") => {
  
  const params = JSON.stringify({
    search: objISearch.search,
    sortBy: objISearch.sortBy,
    sortOrder: objISearch.sortOrder,
    filterBy: {
      negotiated:objISearch.filterBy?.negotiated,
      columnName: "",
      columnValue: ""
    },
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  const data = await httpAviationApim.post<IPaginationResult<IFlightReportNote>>(
    `/flight-report-notes/get/${flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

const createNote = async (newNote: IFlightReportNote,flightReportId:number, strToken:string = "") => {
  const params = JSON.stringify(newNote);
  
  const data = await httpAviationApim.post<IFlightReportNote>(
    `/flight-report-notes/create/${flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  return data;
};

export const notesService = {
  getNotes,
  createNote
};