import { GoATable, GoAButton, GoABlock, GoADropdown, GoADropdownItem, GoASpacer, GoAPagination, GoATableSortHeader, GoABadge } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import FlightReportStatus from '../../model/enum/FlightReportStatus';
import { IFlightReportDashboard } from '../../model/flightReportDashboard/IFlightReportDashboard';
import { IForestArea } from '../../model/ICorporateRegion';
import { IFlightReport } from '../../model/IFlightReport';
import { IFilter } from '../../model/search/IFilter';
import { IPagination } from '../../model/search/IPagination';
import { IPaginationResult } from '../../model/search/IPaginationResult';
import { ISearch } from '../../model/search/ISearch';
import { FlightReportDashboardService } from '../../services/flightReportDashboard/FlightReportDashboardService';
import FlightReportFlagged from './FlightReportFlagged';

interface IFlightReportAllProps {
    forestAreaSelected:never[],
    startDate:string,
    endDate:string,
    searchValue:string,
    onClickFlightReport:(flightReportId: number)=>void,
}

const FlightReportAll: React.FunctionComponent<IFlightReportAllProps> = ({forestAreaSelected,startDate,endDate,searchValue,onClickFlightReport,...props}) => {
  //Navigation
  const navigate = useNavigate();
  //Data set
  const [paginationResults, setPaginationResult] = React.useState<IPaginationResult<IFlightReportDashboard>>();
  //Loader
  const [loading, setIsLoading] = React.useState(true);
  
  //Pagination
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageUsers, setPageFlightReports] = React.useState<any>([]);
  // page number
  const [page, setPage] = React.useState(1);
  //count per page
  const [perPage, setPerPage] = React.useState(10);
  const [previousSelectedPerPage, setPreviousSelectedPerPage] = React.useState(10);

  //Sorting
  const [sortCol, setSortCol] = React.useState("flightReportDate");
  const [sortDir, setSortDir] = React.useState(-1);
  const [isSorting, setIsSorting] = React.useState(false);

  //const sortPaginationResults = React.useMemo(() => sortedPaginationResults(), [sortedPaginationResults]);

  React.useEffect(() => {
    onRefreshFlightReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, endDate, forestAreaSelected, searchValue, sortCol, sortDir]);


  function onRefreshFlightReport(){
    getFlightReports();
    setPageFlightReports(paginationResults?.data.slice(0, perPage));
  };

  //Get flight reports data
  const getFlightReports = async () => {
    setIsLoading(true);
    
    let strSearchValue = searchValue.toLowerCase();
    let sortOrder = sortDir === -1 ? "ASC" : "DESC";
    let startDt = startDate === null || startDate === "" ? null : moment(startDate).format("yyyy-MM-DD");
    let endDt = endDate === null || startDate === "" ? null : moment(endDate).format("yyyy-MM-DD");
    let objForestArea: IForestArea = {
      corporateRegionId: forestAreaSelected != null || forestAreaSelected !== undefined ? forestAreaSelected.map((forestArea)=>{ return forestArea["value"] as string}) : []
    };

    let objIPagination: IPagination = {
      perPage: perPage,
      page: page,
    };

    let objIFilter: IFilter = {
      columnName: "",
      columnValue: "",
      reportDateFrom:startDt,
      reportDateTo:endDt,
      corporateRegions:objForestArea
    };

    let objISearch: ISearch = {
      search: strSearchValue,
      sortBy: sortCol,
      sortOrder: sortOrder,
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    setTimeout(() => {
      FlightReportDashboardService.getSearch(objISearch)
        .then((response: any) => {
          setPaginationResult(p => {
            return sortingData(response.data);
            });
            
          setIsLoading(false);
        })
        .catch((e: Error) => {
          setIsLoading(false);
          sessionStorage.setItem("api_token", "");
          navigate("/");
        });
      }, 200);
  }

  function sortingData(paginationResult: IPaginationResult<IFlightReportDashboard>) {
    const _flightReports = [...(paginationResult.data || [])];
    _flightReports.sort((a, b) => {
      return (
        (a[sortCol as keyof typeof paginationResults] >
        b[sortCol as keyof typeof paginationResults]
          ? -1
          : 1) * sortDir
      );
    });

    paginationResult.data = _flightReports;
    //Only for sorting applied reset pagination
    if(isSorting)
    {
      setPerPage(previousSelectedPerPage | 10);
      setPage(1);
      setPageFlightReports(paginationResults?.data.slice(0, perPage));

      //Reset value
      setIsSorting(false);
      setPreviousSelectedPerPage(perPage);
    }
    return paginationResult;
  }

  function sortData(sortBy: string, sortDir: number) {
    setSortCol(sortBy);
    setSortDir(sortDir);

    setPreviousSelectedPerPage(perPage);
    setPerPage(paginationResults?.paginationInfo.total || 30);
    setPage(1);
    setIsSorting(true);
  }

  //Pagination change page
  function changePage(newPage: any) {
    if(newPage)
    {
      setIsLoading(true);
      const offset = (newPage - 1) * perPage;
      const _flightReports = paginationResults?.data.slice(
        offset,
        offset + perPage
      );

      setPerPage(perPage);
      setPage(newPage);
      setPageFlightReports(_flightReports);
    }
  }

  function changePerPage(name: any, value: any) {
    if(value)
    {
      setIsLoading(true);
      const newPerPage = parseInt(value, 10);
      const offset = (page - 1) * newPerPage;

      const _flightReports = paginationResults?.data.slice(
        offset,
        offset + newPerPage
      );

      setPageFlightReports(_flightReports);
      //setSearchValue("");
      setPerPage(p => {
        return newPerPage;
        });
      setPage(page);
    }
  }

//#endregion

function flightReportClick(flightReportId?:number)
{
    if(flightReportId)
    {
      onClickFlightReport(flightReportId);
    }
}

//on Flag property values change
function onFlagStatusChange(objFlightReport:IFlightReport) {
  if(objFlightReport)
  {
    setIsLoading(true);

    if(paginationResults?.data)
    {
      // 1. Find the flightReport with the provided id
      const currentPaginationResultsIndex = paginationResults?.data.findIndex((value) => value.flightReportId === objFlightReport.flightReportId);
      // 2. Mark the flightReport as complete
      const updatedTodo = {...paginationResults?.data[currentPaginationResultsIndex], isFlagged: objFlightReport.isFlagged};

      // 3. Update the flightReport list with the updated flightReport
      const newPaginationResults = [
        ...paginationResults?.data.slice(0, currentPaginationResultsIndex),
        updatedTodo,
        ...paginationResults?.data.slice(currentPaginationResultsIndex + 1)
      ];
      paginationResults.data = newPaginationResults;
      setPage(1);
      setPaginationResult({...paginationResults});
    }

    setIsLoading(false);
  }
}

  return(
    <>
        <PageLoader visible={loading} />

        <div>
        <div className="divTable">
          <GoATable onSort={sortData} width="100%">
            <thead>
              <tr>
                <th>
                  <GoATableSortHeader name="isFlagged">
                  </GoATableSortHeader >
                </th>
                <th><GoATableSortHeader name="corporateRegionName">
                      Forest Area
                    </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="flightReportDate">
                    Flight Date
                  </GoATableSortHeader>
                </th>
                <th><GoATableSortHeader name="contractRegistrationName">
                      Contract Registration
                    </GoATableSortHeader>
                </th>
                <th><GoATableSortHeader name="vendorName">
                      Aircraft Vendor
                    </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="flightReportId">
                    Flight Report #
                  </GoATableSortHeader>
                </th>
                <th>
                  <GoATableSortHeader name="ao02Number">
                    AO02 #
                  </GoATableSortHeader>
                </th>
                <th>Total flying hours</th>
                <th>
                  <GoATableSortHeader name="status">
                    Status
                  </GoATableSortHeader>
                </th>
              </tr>
            </thead>

            <tbody style={{ position: "sticky", top: 0 }} className='table-body'>
              {paginationResults?.data && paginationResults.data.length > 0 ? (
                paginationResults.data.map((record:any, index:any) => (
                  <tr key={record.flightReportId}>
                    <td>
                      <FlightReportFlagged flightReport={record} onFlagChange={onFlagStatusChange} />
                    </td>
                    <td>{record?.corporateRegionName}</td>
                    <td>
                      {moment(record.flightReportDate).format("yyyy-MM-DD")}
                    </td>
                    <td>{record?.contractRegistrationName}</td>
                    <td>{record?.vendorName}</td>
                    {/* <td style={{ padding: "0 1rem 0 1rem" }}></td> */}
                    <td>
                      <GoAButton
                        {...{ style: '"padding: 0 10px 0 10px;height: 90px;"' }}
                        size="compact"
                        type="tertiary"
                        onClick={() => flightReportClick(record?.flightReportId)}
                      >
                        {record.flightReportId}
                      </GoAButton>
                    </td>
                    <td>{record.ao02Number}</td>
                    <td>{record.totalFlyingHours}</td>
                    <td>{record.status === FlightReportStatus.returned ? 
                            <GoABadge type="emergency" content="Returned" />
                          : record.status === FlightReportStatus.signedOff ? 
                          <GoABadge type="information" content="Signed off" /> : 
                          record.status === FlightReportStatus.inProgress ? 
                          <GoABadge type="information" content="In progress" /> :
                          record.status === FlightReportStatus.approved ? 
                          <GoABadge type="information" content="Approved" /> : "" }
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className="centertext">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </GoATable>
        </div>

        <div className={paginationResults?.data && paginationResults?.data.length > 0 ? "visible pagination" : "not-visible pagination"}>
          <GoABlock alignment="center">
            <GoABlock mb="m" alignment="center" gap="m">
              <span style={{ whiteSpace: "nowrap" }}>Show</span>
              <div className="dropdown-list">
              <GoADropdown name="selPerPage" onChange={changePerPage} value="10" width="8ch">
                <GoADropdownItem value="10"></GoADropdownItem>
                <GoADropdownItem value="20"></GoADropdownItem>
                <GoADropdownItem value="30"></GoADropdownItem>
              </GoADropdown>
              </div>
              <span style={{ whiteSpace: "nowrap" }}>
                of {paginationResults?.paginationInfo.total} items
              </span>
            </GoABlock>
            <GoASpacer hSpacing="fill" />
            <GoAPagination
              itemCount={paginationResults?.paginationInfo.total || 10}
              perPageCount={perPage}
              pageNumber={page}
              onChange={changePage}
            />
          </GoABlock>
        </div>
        </div>
    </>
  );
};

export default FlightReportAll;
