import {
  GoATable,
} from "@abgov/react-components";
import * as React from "react";
import { IFlyingHours } from "../../model/IFlyingHours";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import TotalFlyingHoursColumn from "./TotalFlyingHoursColumn";

interface IViewFlyingDetailProps {
  flyingHoursPaginationResult: IPaginationResult<IFlyingHours> | undefined;
}

const ViewFlyingDetail: React.FunctionComponent<IViewFlyingDetailProps> = ({flyingHoursPaginationResult,...props
}) => {
  return (
    <>
      <div className="white-background">
            <GoATable width="100%">
              <thead>
                <tr style={{ position: "sticky", top: 0 }}>
                  <th className="width10">Fire number</th>
                  <th className="width15">Crew type on board</th>
                  <th className="width15">Activity</th>
                  <th className="width10">Rate type</th>
                  <th className="width8">No.of units</th>
                  <th className="width10">Unit</th>
                  <th>Rate per unit</th>
                  <th>Cost</th>
                  <th className="width8">Internal order</th>
                  <th className="width8">Cost centre</th>
                  <th className="width8">Fund</th>
                  <th className="width8">Account</th>
                </tr>
              </thead>

              <tbody>
                  {flyingHoursPaginationResult && flyingHoursPaginationResult.data !== null ? 
                    flyingHoursPaginationResult.data.map((record: any, idx: number) => (
                      <tr key={idx}>
                      <td>
                        {record?.fireNumberEntity && record?.fireYear ? record?.fireNumberEntity?.fireNumber + "-" + record?.fireYear : 
                          record?.fireNumberEntity ? record?.fireNumberEntity?.fireNumber : ""}
                      </td>
                      <td>
                        {record?.crewTypeEntity ? record?.crewTypeEntity?.code + "-" + record?.crewTypeEntity?.description : ""}
                      </td>
                      <td>
                        {record?.costingActivityEntity ? record?.costingActivityEntity?.code + " " + record?.costingActivityEntity.description : ""}
                      </td>
                      <td>
                        {record?.rateTypeEntity ? record?.rateTypeEntity?.type : ""}
                      </td>
                      <td>
                        {record?.noOfUnits}
                      </td>
                      <td>
                        {record?.rateUnitEntity ? record?.rateUnitEntity?.type : ""}
                      </td>
                      <td>{"$"+record?.ratePerUnit}</td>
                      <td>{"$"+record?.cost}</td>
                      <td>
                        {record?.internalOrder}
                      </td>
                      <td>
                        {record?.costCenter}
                      </td>
                      <td>
                        {record?.fund}
                      </td>
                      <td>
                        {record?.account}
                      </td>
                    </tr>
                  )) : 
                  <></>
                  }
                
                  {/* Total flying hours row */}
                  <tr>
                    <TotalFlyingHoursColumn flyingHoursPaginationResult={flyingHoursPaginationResult}/>
                  </tr>
               
                </tbody>
            </GoATable>
          </div>
    </>
  );
};

export default ViewFlyingDetail;
