
import * as React from "react";
import FlightReportList from "../flightReport/FlightReportList";

interface IDashboardProps {}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {

  //const { isLoggedIn, username, logoutUrl } = useAuthUser();
  
  return (
    <>
      {/* <div className="content-Container">
        <PageHeader title="Aviation reporting" />
      </div> */}

      <FlightReportList />

      {/* <Tabs activeIndex={1} data-testid="approvals-tabs">
        <Tab label="Requires Action" data-testid="approvals-required-tab">
          
        </Tab>
        <Tab label="All Reports" data-testid="approvals-need-attentions-tab">
          
        </Tab>
        
      </Tabs> */}
    </>
  );
};

export default Dashboard;
