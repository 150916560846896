import { httpAviationReportingAuthentication} from "../../http-common";
import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { IFlightReport } from "../../model/IFlightReport";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

const getAuthenticate = async () => {
  const data = await httpAviationReportingAuthentication.post<string>(
    "/authentication/authenticate",
    {
      username: "andrew.mitchell",
      password: "password",
    }
  );

  return data;
};

//Search
const getSearch = async (objISearch: ISearch, strToken:string = "") => {
  const params = JSON.stringify({
    search: objISearch.search,
    sortBy: objISearch.sortBy,
    sortOrder: objISearch.sortOrder,
    filterBy: {
      columnName: objISearch.filterBy?.columnName,
      columnValue: objISearch.filterBy?.columnValue,
      reportDateFrom:objISearch.filterBy?.reportDateFrom,
      reportDateTo:objISearch.filterBy?.reportDateTo,
      corporateRegions:objISearch.filterBy?.corporateRegions,
    },
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  const data = await httpAviationApim.post<IPaginationResult<IFlightReport>>(
    "flight-report/get",
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  
  return data;
};

const getById = async (id: any, strToken:string = "") => {
  const data = await httpAviationApim.post<IFlightReport>(
    `/flight-report/get/${id}`,
    "",
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

const update = async (flightReport:IFlightReport, strToken:string = "") => {
  const params = JSON.stringify(flightReport);

  const data = await httpAviationApim.put<IFlightReport>(
    `/flight-report/update/${flightReport.flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;

};

const updateFlagged = async (flightReport:IFlightReport, strToken:string = "") => {
  const params = JSON.stringify({
    flightReportId: flightReport.flightReportId,
    isFlagged: flightReport.isFlagged,
  });

  // const data = await httpAviationReporting.put<IFlightReport>(
  //   `/flight-report/update/flag/${flightReport.flightReportId}`,
  //   params,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
  //     },
  //   }
  // );

  const data = await httpAviationApim.put<IFlightReport>(
    `/flight-report/update/flag/${flightReport.flightReportId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;

};

const create = async (flightReport:IFlightReport, strToken:string = "") => {
  const params = JSON.stringify(flightReport);

  // const data = await httpAviationReporting.post<IFlightReport>(
  //   "/flight-report/create",
  //   params,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
  //     },
  //   }
  // );

  const data = await httpAviationApim.post<IFlightReport>(
    "/flight-report/create",
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;

};

export const FlightReportService = {
  getAuthenticate,
  getSearch,
  getById,
  update,
  updateFlagged,
  create,
};
