import {
  GoAButton,
  GoAFormItem,
  GoAInput,
  GoAInputDate,
} from "@abgov/react-components";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import PageLoader from "../../components/PageLoader";
import { ICorporateRegion } from "../../model/ICorporateRegion";
import { IFilter } from "../../model/search/IFilter";
import { IPagination } from "../../model/search/IPagination";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";
import {
  bindMutltiSelectDropdown,
  CorporateRegionDomainService,
} from "../../services/domainServices/CorporateRegionDomainService";

import { addDays, getDateWithMonthOffset } from "../../utils/dateHandler";
import { MultiSelect } from "react-multi-select-component";
import FlyingHoursState from "../../components/stateComponent/FlyingHoursState";
import { Tab, Tabs } from "../../components/Tabs";
import FlightReportRequireActionList from "./FlightReportRequireActionList";
import FlightReportAll from "./FlightReportAll";
import { useAuth } from "react-oidc-context";
import { AuthenticationService } from "../../services/authentication/AuthenticationService";

interface IFlightReportListProps {}

const FlightReportList: React.FunctionComponent<IFlightReportListProps> = (
  props
) => {
  const auth = useAuth();
  
  // Navigation
  const navigate = useNavigate();
  //Loader
  const [loading] = React.useState(false);
  //Corporate region
  const [corporateRegionPaginationResult, setCorporateRegionPaginationResult] = React.useState<IPaginationResult<ICorporateRegion>>();
  //Search
  const [searchValue, setSearchValue] = React.useState("");
  
   // eslint-disable-next-line
  const [searchInputKeyPress, setSearchInputKeyPress] = React.useState("");

  //const sortPaginationResults = React.useMemo(() => sortedPaginationResults(), [sortedPaginationResults]);

  //Filter date
  const [startDate, setStartDate] = React.useState("");
  const [startDateError, setStartDateError] = React.useState(false);
  const [endDate, setEndDate] = React.useState("");
  const [endDateError, setEndDateError] = React.useState(false);
  const [maxDate] = React.useState(getDateWithMonthOffset(1));
  const [minEndDate, setMinEndDate] = React.useState(new Date(1950, 1, 1));
  const [showEndDate, setShowEndDate] = React.useState(true);

  //Forest area (Corporate region)
  const [forestAreaSelected, setForestAreaSelected] = React.useState([]);
  const [forestAreaOptions, setForestAreaOptions] = React.useState([]);

  //onKeyPress handler function
  // const keyPressHandler = (
  //   event: React.KeyboardEvent<HTMLInputElement> | any
  // ) => {
  //   //Do something you like with "event"
  //   setSearchInputKeyPress("");
  //   setSearchInputKeyPress(event.key);
  // };

  React.useEffect(() => {
    //const element = document.getElementsByName("flightReportSearch");
    //element[0].addEventListener("keypress", keyPressHandler);

    getCorporateRegions();

    // eslint-disable-next-line
  }, []);

  function searchFlightReports(searchParamValue: string) {
    if (searchInputKeyPress === "Enter") {
      return;
    }

    if (searchParamValue.length >= 3 && searchValue !== searchParamValue) {
      setSearchValue(searchParamValue);
    } else if (searchParamValue.length === 0 && searchValue !== "") {
      setSearchValue("");
    }
  }

  //Corporate region
  function getCorporateRegions() {
    let objIPagination: IPagination = {
      perPage: 20,
      page: 1,
    };

    let objIFilter: IFilter = {
      columnName: "corporateRegionTypeId", 
      columnValue: "4",
    };

    let objISearch: ISearch = {
      search: "",
      filterBy: objIFilter,
      pagination: objIPagination,
    };

    CorporateRegionDomainService.getSearch(objISearch, auth.user?.access_token as string)
      .then((response: any) => {
        if(response.data)
        {
          setCorporateRegionPaginationResult(response.data);
          const forestAreaResult = bindMutltiSelectDropdown(response.data) as [];
          setForestAreaOptions(forestAreaResult);
        }
      })
      .catch((e: Error) => {
        console.log("Error", e);
      });
  }

  //#endregion
  function onClickFlightReport(flightReportId?: number) {
    if (flightReportId) {
      navigate(`/flightReportDetail/${flightReportId}`, {
        state: corporateRegionPaginationResult,
      });
    } else {
      navigate(`/flightReportDetail/new`, {
        state: corporateRegionPaginationResult,
      });
    }
  }

  return (
    <>
    
      <PageLoader visible={loading && auth.isLoading} />
  
      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_R',auth.user?.access_token as string) && <div>
        
        <FlyingHoursState />

        <div className="contentPadding">
          <div className="left width100">
            <div className="left width-auto">
              <PageHeader title="Flight Report Dashboard" />
            </div>
            <div className="right width-auto header-button">
            
            {AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) &&
              <GoAButton type="primary" onClick={() => onClickFlightReport()}>
                Create new +
              </GoAButton>
            } 
            </div>
          </div>
  
          <div className="header-content">
            <div className="filter-content contentInline ">
              <div className="left width25 paddingRight20px">
                <GoAFormItem label="Forest Area">
                  <MultiSelect
                    options={forestAreaOptions}
                    value={forestAreaSelected}
                    onChange={(param: any) => {
                      setForestAreaSelected(param);
                    }}
                    labelledBy="--Select--"
                  />
                </GoAFormItem>
              </div>
              <div className="left width25">
                <div className="left width50">
                  <GoAFormItem label="Start Date">
                    <GoAInputDate
                      name="startDate"
                      placeholder="YYYY-MM-DD"
                      value={startDate}
                      min={new Date(1950, 1, 1)}
                      max={maxDate}
                      width="100%"
                      // trailingIcon="close"
                      // onTrailingIconClick={() => alert('clicked')}
                      error={startDateError}
                      onChange={(name, value) => {
                        let dtResult = moment(value).format("yyyy-MM-DD");
                        let splitDate = dtResult.split("-");
  
                        if (dtResult !== "Invalid date") {
                          if (parseInt(splitDate[0]) < 1950) {
                            setStartDateError(true);
                            endDate !== "" ? setEndDate(endDate) : setEndDate("");
                            setShowEndDate(true);
                          } else if (parseInt(splitDate[2]) > 31) {
                            setStartDateError(true);
                            endDate !== "" ? setEndDate(endDate) : setEndDate("");
                            setShowEndDate(true);
                          } else {
                            setStartDateError(false);
                            setStartDate(dtResult as string);
                            let dtObj = addDays(new Date(value), 1);
                            setMinEndDate(dtObj);
                            setShowEndDate(false);
                          }
                        } else {
                          setStartDateError(true);
                          endDate !== "" ? setEndDate(endDate) : setEndDate("");
                          setShowEndDate(true);
                        }
                      }}
                    />
                  </GoAFormItem>
                </div>
                <div className="left width50 paddingLeft20px">
                  <GoAFormItem label="End Date">
                    <GoAInputDate
                      name="filterEndDate"
                      placeholder="YYYY-MM-DD"
                      value={endDate}
                      min={minEndDate}
                      max={maxDate}
                      width="100%"
                      error={endDateError}
                      disabled={showEndDate}
                      onChange={(name, value) => {
                        let dtResult = moment(value).format("yyyy-MM-DD");
                        let splitDate = dtResult.split("-");
  
                        if (dtResult !== "Invalid date") {
                          if (parseInt(splitDate[0]) < 1950) {
                            setEndDateError(true);
                          } else if (parseInt(splitDate[2]) > 31) {
                            setEndDateError(true);
                          } else if (value < minEndDate) {
                            setEndDateError(true);
                          } else {
                            setEndDateError(false);
                            value >= minEndDate
                              ? setEndDate(value as string)
                              : setEndDateError(true);
                          }
                        } else {
                          setEndDateError(true);
                        }
                      }}
                    />
                  </GoAFormItem>
                </div>
              </div>
  
              <div className="right width50 paddingLeft20px">
                <GoAFormItem label="Search">
                  <GoAInput
                    trailingIcon="search"
                    name="flightReportSearch"
                    placeholder="Search by contract registration, vendor, AO02 # or flight report #"
                    onChange={(name, value) => searchFlightReports(value)}
                    onTrailingIconClick={() => searchFlightReports(searchValue)}
                    value={""}
                    width="100%"
                  />
                </GoAFormItem>
              </div>
            </div>
          </div>
  
          <Tabs activeIndex={0} data-testid="approvals-tabs">
            <Tab label="Requires Action" data-testid="approvals-required-tab">
              <FlightReportRequireActionList
                forestAreaSelected={forestAreaSelected}
                startDate={startDate}
                endDate={endDate}
                searchValue={searchValue}
                onClickFlightReport={onClickFlightReport}
              />
            </Tab>
            <Tab label="All Reports" data-testid="approvals-need-attentions-tab">
              <FlightReportAll
                forestAreaSelected={forestAreaSelected}
                startDate={startDate}
                endDate={endDate}
                searchValue={searchValue}
                onClickFlightReport={onClickFlightReport}
              />
            </Tab>
          </Tabs>
  
        </div>
      </div>
                    
      }

      {!(auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_R',auth.user?.access_token as string)) && 
        <div className="contentPadding">
          <div className="left width100">
            <div className="left width-auto">
              <PageHeader title="Flight Report Dashboard" />
            
              <div className="header-content">
                <p>You dont have permission</p>
              </div>
          </div>
        </div>
        </div>
      }
    </>
  );
};

export default FlightReportList;
