import { httpRulesEngineApim } from "../utils/rulesEngineAPIMConfig";

// const getRules = async (feature:string, api:string, strToken:string = "") => {
//     //const apiUrl = `https://wmtt-rules-engine.azurewebsites.net/api/v1.0/rules-engine/rules?feature=${feature}&api=${api}&isFrontEndValidation=true`;
//     const apiUrl = `${httpRulesEngineApim}rules?feature=${feature}&api=${api}&isFrontEndValidation=true`;

//     try {
//         const response = await fetch(apiUrl, {
//             method: 'GET',
//             headers: {
//                 Authorization: `Bearer ${strToken}`,
//             },
//         });
// debugger
//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }

//         return await response.json();
//     } catch (error) {
//         console.error('Fetch error:', error);
//     }
// }

const getRules = async (feature:string, api:string, strToken:string = "") => {
    
    const data = await httpRulesEngineApim.get(
      `rules?feature=${feature}&api=${api}&isFrontEndValidation=true`,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );
   
    return data;
  };

export const validationEnginService = {
    getRules
};
