import moment from "moment";
import { FlightReport } from "../model/IFlightReport";
import { IFilter } from "../model/search/IFilter";
import { IPagination } from "../model/search/IPagination";
import { ISearch } from "../model/search/ISearch";
import { FlightReportService } from "../services/flightReport/FlightReportService";
import { HiringContractService } from "../services/HiringContractService";

const getFlightReportById = async (flightReportId: number, strToken:string) => {
  await FlightReportService.getById(flightReportId);
};

const createAndUpdate = async (flightReport:FlightReport, auth : any, strToken:string) => {

  if (flightReport?.flightReportId) {
    //update last updated by & last updated on timestamp
    flightReport.updateTimestamp = moment(new Date()).format("yyyy-MM-DD");
    flightReport.updateUserId = auth?.user?.profile?.name ?? "wildfire"; 

    return await FlightReportService.update(flightReport, strToken)
      
  } else {
      //update created by & created on timestamp
      flightReport.createTimestamp = moment(new Date()).format("yyyy-MM-DD");
      flightReport.createUserId = auth?.user?.profile?.name ?? "wildfire"; 

      return await FlightReportService.create(flightReport, strToken);
  }

};

const updateFlagged = async (flightReport:FlightReport, strToken:string) => {
  if (flightReport?.flightReportId) {
    return await FlightReportService.updateFlagged(flightReport, strToken);
  }
};

//#region "Hiring Contract"
async function getHiringContract(reportDate:string)
{
  let objIPagination: IPagination = {
    perPage: 1500,
    page: 1,
  };

  let objIFilter: IFilter = {
    columnName: "reportDate",
    columnValue: moment(reportDate).format("yyyy-MM-DD")
  };

  let objISearch: ISearch = {
    search: "",
    filterBy: objIFilter,
    pagination: objIPagination,
  };

  var response = await HiringContractService.getSearch(objISearch)
  return response.data.data;
}

export const FlightReportCRUD = {
  getFlightReportById,
  createAndUpdate,
  updateFlagged,
  getHiringContract,
};
