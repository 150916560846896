import * as React from "react";
import { IFlyingHours } from "../../model/IFlyingHours";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { calculateTotalFlyingHours } from "../../services/flyingHours/FlyingHoursService";

interface ITotalFlyingHoursColumnProps {
  flyingHoursPaginationResult: IPaginationResult<IFlyingHours> | undefined;
}

const TotalFlyingHoursColumn: React.FunctionComponent<ITotalFlyingHoursColumnProps> = ({flyingHoursPaginationResult,...props
}) => {
  return (
    <>
        <td colSpan={3}></td>
        <td>
          Total flying hours
        </td>
        <td colSpan={8} className="fontBold">
          {
            calculateTotalFlyingHours(flyingHoursPaginationResult)
          }
        </td>
    </>
  );
};

export default TotalFlyingHoursColumn;
