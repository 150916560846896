import moment from "moment";
import { CostingActivity } from "./domainServices/ICostingActivity";
import { ICrewType } from "./domainServices/ICrewType";
import { FireNumber } from "./domainServices/IFireNumber";
import { RateType } from "./domainServices/IRateType";
import { RateUnit } from "./domainServices/IRateUnit";
import { IArrayOption } from "./IArrayOption";

export interface IFlyingHours{
    flightReportCostDetailsId?:string,
    flightReportId?:number,

    fireNumberId?:any,
    fireNumber?:string,
    fireNumberEntity?:FireNumber | undefined,
    fireNumberList?:IArrayOption[],
    fireYear?:number,

    crewTypeId:any,
    crewTypeEntity?:ICrewType,
    crewTypeList?:IArrayOption[],

    costingActivityId:string,
    costingActivityCode?:string,
    costingActivityEntity?:CostingActivity | undefined,
    costingActivityList?:IArrayOption[],

    rateTypeId:string,
    rateTypeEntity?:RateType | undefined,
    rateTypeList?:IArrayOption[],

    noOfUnits:any,
    rateUnitId:string,
    rateUnitEntity?:RateUnit | undefined,
    rateUnitList?:IArrayOption[],

    ratePerUnit?:any,
    cost?:any,

    internalOrder?:string,
    internalOrderList?:IArrayOption[],
    costCenter?:string,
    costCenterList?:IArrayOption[],
    fund?:string,
    fundList?:IArrayOption[],
    account?:string,
    accountList?:IArrayOption[],

    createUserId?:string,
    createTimestamp?:string,
    updateTimestamp?:string | null,
    updateUserId?: string | null,   

    deleted?:boolean,
    expenseType?:string,

    isChecked?:boolean,
}

export class FlyingHours implements IFlyingHours{
    readonly flightReportCostDetailsId?:string;
    flightReportId?:number;
    
    readonly fireNumberId?:any;
    readonly fireNumber?:string;
    fireNumberEntity?:FireNumber;
    fireNumberList?:IArrayOption[];
    fireYear?:number;

    readonly crewTypeId:any;
    crewTypeEntity?:ICrewType;
    crewTypeList?:IArrayOption[];

    readonly costingActivityId:string;
    readonly costingActivityCode?:string;
    costingActivityEntity?:CostingActivity;
    costingActivityList?:IArrayOption[];

    rateTypeId:string;
    rateTypeEntity?:RateType;
    rateTypeList?:IArrayOption[];

    readonly noOfUnits:any;

    rateUnitId:string;
    rateUnitEntity?:RateUnit;
    rateUnitList?:IArrayOption[];

    ratePerUnit?:any;
    readonly cost:any;

    readonly internalOrder?:string;
    internalOrderList?:IArrayOption[];
    readonly costCenter?:string;
    costCenterList?:IArrayOption[];
    readonly fund?:string;
    fundList?:IArrayOption[];
    readonly account?:string;
    accountList?:IArrayOption[];

    createUserId?:string ;
    createTimestamp?:string;
    updateTimestamp?:string | null;
    updateUserId?: string | null;  

    deleted?:boolean;
    expenseType?:string;

    isChecked?:boolean;

    constructor(from?: Partial<IFlyingHours>) {
        this.flightReportCostDetailsId = from?.flightReportCostDetailsId ?? "00000000-0000-0000-0000-000000000000";
        this.flightReportId = from?.flightReportId;

        this.fireNumberId = from?.fireNumberId;
        this.fireNumber = from?.fireNumber ?? "";
        this.fireNumberEntity= from?.fireNumberEntity ?? undefined;
        this.fireNumberList = from?.fireNumberList ?? undefined;
        this.fireYear = from?.fireYear;
        
        this.crewTypeId = from?.crewTypeId ?? null;
        this.crewTypeEntity= from?.crewTypeEntity ?? undefined;
        this.crewTypeList = from?.crewTypeList ?? undefined;

        this.costingActivityId = from?.costingActivityId ?? "00000000-0000-0000-0000-000000000000";
        this.costingActivityCode = from?.costingActivityCode ?? "";
        this.costingActivityEntity= from?.costingActivityEntity ?? undefined;
        this.costingActivityList = from?.costingActivityList ?? undefined;

        this.rateTypeId = from?.rateTypeId ?? "";
        this.rateTypeEntity= from?.rateTypeEntity ?? undefined;
        this.rateTypeList = from?.rateTypeList ?? undefined;

        this.noOfUnits = from?.noOfUnits ?? "";

        this.rateUnitId = from?.rateUnitId ?? "";
        this.rateUnitEntity= from?.rateUnitEntity ?? undefined;
        this.rateUnitList = from?.rateUnitList ?? undefined;

        this.ratePerUnit = from?.ratePerUnit ?? 0;
        
        this.cost = this.calculateCost(from?.noOfUnits,from?.ratePerUnit);

        this.internalOrder = from?.internalOrder ?? "";
        this.internalOrderList = from?.internalOrderList ?? undefined;
        this.costCenter = from?.costCenter ?? "";
        this.costCenterList = from?.costCenterList ?? undefined;
        this.fund = from?.fund ?? "";
        this.fundList = from?.fundList ?? undefined;
        this.account = from?.account ?? "";
        this.accountList = from?.accountList ?? undefined;

        this.createUserId=from?.createUserId ?? "";
        this.createTimestamp=this.dateFormat(from?.createTimestamp) ?? null;
        this.updateTimestamp = moment(from?.updateTimestamp).isValid() ? moment(from?.updateTimestamp).format("yyyy-MM-DD") : null;
        this.updateUserId=from?.updateUserId ?? null;  
    
        this.deleted = from?.deleted ?? false;
        this.expenseType = from?.expenseType ?? "";

        this.isChecked = from?.isChecked ?? false;
    }

    dateFormat(value?: string): string
      {
        if (!value) return "";

        return moment(value).isValid() ? moment(value).format("yyyy-MM-DD") : value;
      }

    calculateCost(noOfUnits: any,ratePerUnit:any): any
    {
      if(noOfUnits && ratePerUnit)
        return (noOfUnits * ratePerUnit).toFixed(2);
      else
        return 0;
    }
}