import { httpFinancial1GXApim } from "../../utils/financialAPIMConfig";
import { ICostCenter } from "../../model/1GXCoding/ICostCenter";
import { IFilterOneGX } from "../../model/1GXCoding/IFilterOneGX";
import { IArrayOption } from "../../model/IArrayOption";
import { IFinancialResult } from "../../model/search/IPaginationResult";

//Search
const getSearch = async (objIFilter: IFilterOneGX, strToken:string = "") => {
  
    const data = await httpFinancial1GXApim.get(
      `GetCostCenter?listType=${objIFilter.listType}&Search=${objIFilter.Search}`,
      {
        headers: {
          Authorization: `Bearer ${strToken}`,
        },
      }
    );

    return data;
  };

  export const CostCenterService = {
    getSearch,
  };

  export const bindCostCenterDropdown = (costCenterPaginationResult:IFinancialResult<ICostCenter>): IArrayOption[] => {
    const options = [];
    
    if(costCenterPaginationResult.data)
    {
      for (let i = 0; i < costCenterPaginationResult.data.length; i++) {
        let obj : IArrayOption = {label: costCenterPaginationResult.data[i].costCenterDescription + " - " + costCenterPaginationResult.data[i].costCenter, value: costCenterPaginationResult.data[i].costCenter};
        options.push(obj);
      }
    }

    return options;
  }

