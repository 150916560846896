import { httpAviationApim } from "../../utils/aviationAPIMConfig";
import { IFlightReportDashboard } from "../../model/flightReportDashboard/IFlightReportDashboard";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch,strToken:string = "") => {
  const params = JSON.stringify({
    search: objISearch.search,
    sortBy: objISearch.sortBy,
    sortOrder: objISearch.sortOrder,
    filterBy: {
      columnName: objISearch.filterBy?.columnName,
      columnValue: objISearch.filterBy?.columnValue,
      reportDateFrom:objISearch.filterBy?.reportDateFrom,
      reportDateTo:objISearch.filterBy?.reportDateTo,
      corporateRegions:objISearch.filterBy?.corporateRegions,
    },
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  // const data = await httpAviationReporting.post<IPaginationResult<IFlightReportDashboard>>(
  //   "/flight-report-dashboard/get",
  //   params,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
  //     },
  //   }
  // );

  const data = await httpAviationApim.post<IPaginationResult<IFlightReportDashboard>>(
    "flight-report-dashboard/get",
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );
  
  return data;
};

export const FlightReportDashboardService = {
  getSearch
};
