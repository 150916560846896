import { httpDomainServiceApim } from "../../utils/domainServiceAPIMConfig";
import { IRate } from "../../model/domainServices/IRate";
import { IPaginationResult } from "../../model/search/IPaginationResult";
import { ISearch } from "../../model/search/ISearch";

//Search
const getSearch = async (objISearch: ISearch, strToken:string = "") => {
  
  const params = JSON.stringify({
    search: objISearch.search,
    sortBy: objISearch.sortBy,
    sortOrder: objISearch.sortOrder,
    filterBy: {
      negotiated:objISearch.filterBy?.negotiated,
      columnName: "",
      columnValue: ""
    },
    paginationInfo: {
      perPage: objISearch.pagination.perPage,
      page: objISearch.pagination.page,
    },
  });

  const data = await httpDomainServiceApim.post<IPaginationResult<IRate>>(
    "/expense_rate_details/get",
    params,
    {
      headers: {
        Authorization: `Bearer ${strToken}`,
      },
    }
  );

  return data;
};

export const RateService = {
  getSearch
};
