import { GoABadge, GoAButton, GoAButtonGroup, GoAFormItem, GoAIcon, GoAModal } from '@abgov/react-components';
import moment from 'moment';
import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import FlightReportStatus from '../../model/enum/FlightReportStatus';
import { FlightReport } from '../../model/IFlightReport';
import { FlightReportCRUD } from '../../operations/FlightReportCRUD';
import { AuthenticationService } from '../../services/authentication/AuthenticationService';
import FlightReportFlagged from './FlightReportFlagged';

interface IFlightReportDetailHeaderProps {
    flightReport: FlightReport;
    isEditing?:boolean;
    onUpdate: (value: FlightReport) => void;
    onChange: (value: FlightReport) => void;
}

const FlightReportDetailHeader: React.FunctionComponent<IFlightReportDetailHeaderProps> = ({flightReport,isEditing,onUpdate,onChange,...props}) => {
  const auth = useAuth();
  // Navigation
  const navigate = useNavigate();
    
  //Loader
  const [loading, setIsLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [headerModal, setHeaderModal] = React.useState("Are you sure you want to sign off on this report?");
  const [cancelButton, setCancelButton] = React.useState("No, cancel");
  const [submitButton, setSubmitButton] = React.useState("Yes, I do");

  const [returnedButton, setReturnedButton] = React.useState(false);
  const [approvedButton, setApprovedButton] = React.useState(false);
  
  async function onSignedOff()
  {
    setReturnedButton(false);
    setApprovedButton(false);
    setHeaderModal("Are you sure you want to sign off on this report?");
    setCancelButton("No, cancel");
    setSubmitButton("Yes, I do");
    setShowModal(true);
  }

  async function onReturned()
  {
    setReturnedButton(true);
    setApprovedButton(false);
    setHeaderModal("Are you sure you want to return on this report?");
    setCancelButton("Cancel");
    setSubmitButton("Yes, Return");
    setShowModal(true);
  }

  async function onApproved()
  {
    setApprovedButton(true);
    setReturnedButton(false);
    
    setHeaderModal("Are you sure you want to approve on this report?");
    setCancelButton("Cancel");
    setSubmitButton("Yes, Approve");
    setShowModal(true);
  }

  async function onCancel() {
    setIsLoading(true);
    setShowModal(false);

    setIsLoading(false);
  }

  //On signed off yes submit button
  async function onSubmit(status:string = FlightReportStatus.signedOff) {
    setIsLoading(true);

    let newValue = new FlightReport();
    newValue =  new FlightReport({...flightReport, isFlagged:false, status:status});

    try{
      //Update flag status
      await FlightReportCRUD.createAndUpdate(newValue, auth, auth.user?.access_token as string);

      //pass update value to parent to FlightReportDetail
      onUpdate(newValue);

      setShowModal(false);
      
      setIsLoading(false);
    }
    catch(e:any)
    {
      console.log(e);
      setShowModal(false);
      setIsLoading(false);
    }
  }

      //on property values change
      function onFlagStatusChange(record:FlightReport) {
        setIsLoading(true);
  
        let newValue = new FlightReport();
  
        newValue =  new FlightReport({...record});
  
        //send on change to parent component
        onChange(newValue);
  
        setIsLoading(false);
      }
  
  return (
    <>
      <PageLoader visible={loading} />

      <div className="new-layout-main">
        <div className="new-layout-header">
          <div>
            <GoAButton type="tertiary" leadingIcon="arrow-back" onClick={()=>navigate("/dashboard")}>
              Back to Dashboard
            </GoAButton>
          </div>
          <div className="new-layout-header-section">
            <div className="form-row">
              <div className="col-50">
                <div className="contentInline detailHeader">
                  <div className="heading left paddingLeft0px">
                    <label>
                      {flightReport?.flightReportId
                        ? "Flight Report " + flightReport?.flightReportId ??
                          "..."
                        : "Create new flight report"}
                    </label>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Flight Date">
                      <label className='detailsValue'>
                        {flightReport?.flightReportDate ? moment(flightReport?.flightReportDate).format(
                          "yyyy-MM-DD"
                        ) : "-"}
                      </label>
                    </GoAFormItem>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Contract Registration">
                      <label className='detailsValue'>
                        {flightReport?.contractRegistration ?
                          flightReport?.contractRegistration
                            ?.aircraftRegistration : "-"
                        }
                      </label>
                    </GoAFormItem>
                  </div>
                  <div className="left">
                    <GoAFormItem label="Status">
                      <label>
                        {flightReport?.status ===
                        FlightReportStatus.returned ? (
                          <GoABadge type="emergency" content="Returned" />
                        ) : flightReport?.status ===
                          FlightReportStatus.signedOff ? (
                          <GoABadge type="information" content="Signed off" />
                        ) : flightReport?.status ===
                          FlightReportStatus.inProgress ? (
                          <GoABadge type="information" content="In progress" />
                        ) : flightReport?.status ===
                          FlightReportStatus.approved ? (
                          <GoABadge type="information" content="Approved" />
                        ) : (
                          "-"
                        )}
                      </label>
                    </GoAFormItem>
                  </div>
                </div>
              </div>

              <div className="col-50 headingOptions">
                <div className="right">
                  <div className="col-15 padding-left-right10">
                    <GoAIcon type="information-circle" />
                  </div>
                  <div className="col-15 padding-left-right10">
                    
                      <FlightReportFlagged
                        flightReport={flightReport}
                        onFlagChange={onFlagStatusChange}
                      />
                    
                  </div>

                  {flightReport?.status !== FlightReportStatus.approved ? (
                    <div className="col-50">
                      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) 
                          && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRptSignoff_E',auth.user?.access_token as string) && <div>
                        <GoAButton
                          size="compact"
                          type="primary"
                          disabled={
                            isEditing ||
                            flightReport?.status === FlightReportStatus.signedOff
                          }
                          onClick={() => onSignedOff()}
                        >
                          Sign off
                        </GoAButton>
                      </div>}
                    </div>
                  ) : (
                    <></>
                  )}
                  

                  {flightReport?.status === FlightReportStatus.signedOff ? (
                    <div className="col-50">
                      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) 
                        && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRptAppRet_E',auth.user?.access_token as string) && <div>
                        <GoAButton
                          size="compact"
                          type="primary"
                          onClick={() => onReturned()}
                        >
                          Return
                        </GoAButton>
                      </div>}
                    </div>
                  ) : (
                    <></>
                  )}

                  {flightReport?.status === FlightReportStatus.signedOff ? (
                    <div className="col-50">
                      {auth.isAuthenticated && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRpt_W',auth.user?.access_token as string) 
                        && AuthenticationService.hasResourceRole('FlightReports', 'p_Aviat_FlightRptAppRet_E',auth.user?.access_token as string) && <div>
                        <GoAButton
                          size="compact"
                          type="primary"
                          onClick={() => onApproved()}
                        >
                          Approve
                        </GoAButton>
                      </div>}
                    </div>
                  ) : (
                    <></>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------------------------- */}

      <GoAModal
        heading=""
        open={showModal}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton
              size="compact"
              type="secondary"
              onClick={() => onCancel()}
            >
              {cancelButton}
            </GoAButton>
            <GoAButton
              size="compact"
              type="primary"
              onClick={() =>
                returnedButton
                  ? onSubmit(FlightReportStatus.returned)
                  : approvedButton
                  ? onSubmit(FlightReportStatus.approved)
                  : onSubmit()
              }
            >
              {submitButton}
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>{headerModal}</p>
      </GoAModal>
    </>
  );
};

export default FlightReportDetailHeader;
